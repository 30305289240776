import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import "bootstrap/dist/css/bootstrap.min.css";
import "./navbar.css";
import logo from "../../assets/logo.png";
import { AiOutlineMenu, AiOutlineCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";


const NavBarSec = () => {
  const navigation = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(!show);
    console.log("==========>click", show);
  };
  const [click, setIsClick] = useState(false);
  console.log("==========>", show);
  return (
    <>
      <style>{dynamicCss}</style>
      <div className="nav_bar_wrapper">
        <div className="nav_bar_inner_box">
          <div>
            <Link className="navi_lins" to="/">
              <img src={logo} className="img-fluid nav_logo" />
            </Link>
          </div>
          <div className="nav_content_wrappper">
            <Link className="navi_lins" to="/projects">
              <div className="nav_items">PROJECTS</div>
            </Link>
            <div className="nav_items nav_serivice_hover">
              SERVICES
              <div className="services_drop_downs">
                <div>
                  <div className="serice_type">APP DEVELOPMENT</div>
                  <div className="drop_donw_items_box">
                    <Link className="link_type" to="/androidAppDevelopment">
                      <div className="serves_items">
                        -Android App Development
                      </div>
                    </Link>
                    <Link className="link_type" to="/IOSAppDevelopment">
                      <div className="serves_items">-IOS App Development</div>
                    </Link>
                    <Link className="link_type" to="/CrossMobileDevelopment">
                      <div className="serves_items">
                        -Cross Platform Mobile Development
                      </div>
                    </Link>
                  </div>
                </div>
                <div>
                  <div className="serice_type">WEB APPLICATIONS</div>
                  <div className="drop_donw_items_box">
                    <Link className="link_type" to="/CustomWebApps">
                      <div className="serves_items">-Custom Web Apps</div>
                    </Link>
                    <Link className="link_type" to="/ProgressiveWebApps">
                      <div className="serves_items">-Progressive Web Apps</div>
                    </Link>
                    <Link className="link_type" to="/FrontEndServices">
                      <div className="serves_items">
                        -Front End Development Services
                      </div>
                    </Link>
                  </div>
                </div>
                <div>
                  <div className="serice_type">PRODUCT ENGINEERING</div>
                  <div className="drop_donw_items_box">
                    <Link className="link_type" to="/SaaSDevelopment">
                      <div className="serves_items">
                        -SaaS Application Development
                      </div>
                    </Link>
                    <Link className="link_type" to="/LeanDevelopment">
                      <div className="serves_items">
                        -Lean Product Development
                      </div>
                    </Link>
                    <Link
                      className="link_type"
                      to="/CustomApplicationDevelopment"
                    >
                      <div className="serves_items">
                        -Custom Application Development
                      </div>
                    </Link>
                  </div>
                </div>
                <div>
                  <div className="serice_type">TRENDING TECHNOLOGIES</div>
                  <div className="drop_donw_items_box">
                    <Link className="link_type" to="/iotDevelopment">
                      <div className="serves_items">-IOT Development</div>
                    </Link>
                    <Link
                      className="link_type"
                      to="/augmentedRealityDevelopment"
                    >
                      <div className="serves_items">
                        -Augmented Reality App Development
                      </div>
                    </Link>
                    <Link className="link_type" to="/chatbotDevelopment">
                      <div className="serves_items">-Chatbot Development</div>
                    </Link>
                  </div>
                </div>
                <div style={{display:"flex",justifyContent:"start",width:"62%",flexDirection:"column"}}>
                  <div className="serice_type">AMAZON</div>
                  <div className="drop_donw_items_box" >
                    <Link className="link_type" to="/amazonServices">
                      <div className="serves_items" >-Amazon Services</div>
                    </Link>
                    <Link className="link_type" to="/adServices">
                      <div className="serves_items" >-Ad Services</div>
                    </Link>
                    
                  </div>
                </div>
              </div>
            </div>
            <Link to="/pressRelease" className="navi_lins">
              <div className="nav_items">RESOURCES</div>
            </Link>
            <Link className="navi_lins" to="/company">
              <div className="nav_items">COMPANY</div>
            </Link>
            <Link className="navi_lins" to="/blog">
              <div className="nav_items">BLOG</div>
            </Link>
            <Link className="navi_lins" to="/technologies">
              <div className="nav_items">TECHNOLOGIES</div>
            </Link>
          </div>
          <Link className="navi_lins" to="/contact">
            <div className="nav_contact_us">CONTACT US</div>
          </Link>
        </div>
      </div>
      <div className="mobile_vav_wrapper">
        <div>
          <img src={logo} className="img-fluid nav_logo" />
        </div>
        <div className="icon_mbl" onClick={() => handleShow()}>
          <AiOutlineMenu className="mbl_menu_icon" />
        </div>
      </div>
      <Offcanvas
        className="mbl_menu_slide"
        show={show}
        onHide={handleClose}
        backdrop="static"
        placement="end"
      >
        <div className="mbl_nav_profile">
          <div>
            <img src={logo} className="nav_logo" />
          </div>
          <div onClick={() => handleClose()}>
            <AiOutlineCloseCircle className="mbl_menu_icon" />
          </div>
        </div>
        <div className="mbl_nav_items_box">
          <Link className="navi_lins" to="/projects">
            <div className="mbl_nav_items">Projects</div>
          </Link>
          <div
            className="mbl_nav_items nav_serivice_hover"
            onClick={() => setIsClick(!click)}
          >
            Services
            {click && (
              <div className="services_drop_downs">
                <div>
                  <div className="serice_type">APP DEVELOPMENT</div>
                  <div className="drop_donw_items_box">
                    <Link className="link_type" to="/androidAppDevelopment">
                      <div className="serves_items">
                        -Android App Development
                      </div>
                    </Link>
                    <Link className="link_type" to="/IOSAppDevelopment">
                      <div className="serves_items">-IOS App Development</div>
                    </Link>
                    <Link className="link_type" to="/CrossMobileDevelopment">
                      <div className="serves_items">
                        -Cross Platform Mobile Development
                      </div>
                    </Link>
                  </div>
                </div>
                <div>
                  <div className="serice_type">WEB APPLICATIONS</div>
                  <div className="drop_donw_items_box">
                    <Link className="link_type" to="/CustomWebApps">
                      <div className="serves_items">-Custom Web Apps</div>
                    </Link>
                    <Link className="link_type" to="/ProgressiveWebApps">
                      <div className="serves_items">-Progressive Web Apps</div>
                    </Link>
                    <Link className="link_type" to="/FrontEndServices">
                      <div className="serves_items">
                        -Front End Development Services
                      </div>
                    </Link>
                  </div>
                </div>
                <div>
                  <div className="serice_type">PRODUCT ENGINEERING</div>
                  <div className="drop_donw_items_box">
                    <Link className="link_type" to="/SaaSDevelopment">
                      <div className="serves_items">
                        -SaaS Application Development
                      </div>
                    </Link>
                    <Link className="link_type" to="/LeanDevelopment">
                      <div className="serves_items">
                        -Lean Product Development
                      </div>
                    </Link>
                    <Link
                      className="link_type"
                      to="/CustomApplicationDevelopment"
                    >
                      <div className="serves_items">
                        -Custom Application Development
                      </div>
                    </Link>
                  </div>
                </div>
                <div>
                  <div className="serice_type">TRENDING TECHNOLOGIES</div>
                  <div className="drop_donw_items_box">
                    <Link className="link_type" to="/iotDevelopment">
                      <div className="serves_items">-IOT Development</div>
                    </Link>
                    <Link
                      className="link_type"
                      to="/augmentedRealityDevelopment"
                    >
                      <div className="serves_items">
                        -Augmented Reality App Development
                      </div>
                    </Link>
                    <Link className="link_type" to="/chatbotDevelopment">
                      <div className="serves_items">-Chatbot Development</div>
                    </Link>
                  </div>
                </div>
                <div>
                  <div className="serice_type">AMAZON</div>
                  <div className="drop_donw_items_box" >
                    <Link className="link_type" to="/amazonServices">
                      <div className="serves_items" >-Amazon Services</div>
                    </Link>
                    <Link className="link_type" to="/adServices">
                      <div className="serves_items" >-Ad Services</div>
                    </Link>

                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="mbl_nav_items">Resources</div>
          <Link className="navi_lins" to="/company">
            <div className="mbl_nav_items">Company</div>
          </Link>
          <Link className="navi_lins" to="/technologies">
            <div className="mbl_nav_items">Technologies</div>
          </Link>
          <Link className="navi_lins" to="/contact">
            <div className="mbl_nav_ContactUS">Contact Us</div>
          </Link>
        </div>
      </Offcanvas>
    </>
  );
};

export default NavBarSec;
const dynamicCss = `
`;
