import React from "react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
function Navbar({ styling, background }) {
  const navigation = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const [anchorE2, setAnchorE2] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [serviceOpen, setServiceOpen] = React.useState(false);
  const [resourcesOpen, setResourcesOpen] = React.useState(false);

  const handlePopoverOpen2 = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handlePopoverClose2 = () => {
    setAnchorE2(null);
  };
  const handleOpenModal = () => {
    navigation("/services");
  };
  const handleMobileOpen = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleMobileClose = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleServiceOpen = () => {
    setServiceOpen(!serviceOpen);
  };
  const handleServiceClose = () => {
    setServiceOpen(!serviceOpen);
  };
  const handleResourcesOpen = () => {
    setResourcesOpen(!resourcesOpen);
  };
  const handleResourcesClose = () => {
    setResourcesOpen(!resourcesOpen);
  };
  const basicUrl = "https://bitsbayltd.com";
  // const basicUrl = "http://localhost:3000";

  return (
    <>
      {mobileOpen && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              backgroundColor: "white",
              width: "90%",
              minHeight: 300,
              position: "absolute",
              top: 84,
              zIndex: 1,
            }}
          >
            <div
              onClick={() => navigation("/projects")}
              style={{
                fontSize: 18,
                marginLeft: 10,
                marginRight: 10,
                marginTop: 13,
              }}
            >
              PROJECTS
            </div>
            <div
              style={{
                fontSize: 18,
                marginRight: 10,
                display: "flex",
                justifyContent: "space-between",
                marginTop: 13,
              }}
            >
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                }}
              >
                SERVICES
              </div>

              {serviceOpen ? (
                <div
                  style={{
                    backgroundColor: "#FE0701",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderRadius: 4,
                  }}
                >
                  <div
                    onClick={handleServiceOpen}
                    style={{
                      color: "white",
                    }}
                  >
                    <ArrowDropUpIcon />
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "#FE0701",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderRadius: 4,
                  }}
                >
                  <div
                    onClick={handleServiceClose}
                    style={{
                      color: "white",
                    }}
                  >
                    <ArrowDropDownOutlinedIcon />
                  </div>
                </div>
              )}
            </div>
            {serviceOpen && (
              <div
                style={{ background: "#F6F6F6", width: "100%", height: "100%" }}
              >
                <div style={{ marginLeft: 10, padding: 20, color: "#000000" }}>
                  APP DEVELOPMENT
                </div>
                <div
                  style={{ marginTop: 30, marginLeft: 25, color: "#666666" }}
                >
                  <a
                    style={{ color: "#666666", textDecoration: "none" }}
                    href={`${basicUrl}/androidAppDevelopment`}
                  >
                    -Android App Development
                  </a>
                </div>
                <div
                  style={{ marginTop: 30, marginLeft: 25, color: "#666666" }}
                >
                  <a
                    style={{ color: "#666666", textDecoration: "none" }}
                    href={`${basicUrl}/IOSAppDevelopment`}
                  >
                    -IOS App Development
                  </a>
                </div>
                <div
                  style={{ marginTop: 30, marginLeft: 25, color: "#666666" }}
                >
                  <a
                    style={{ color: "#666666", textDecoration: "none" }}
                    href={`${basicUrl}/CrossMobileDevelopment`}
                  >
                    -Cross Platform Mobile Development
                  </a>
                </div>
                <div style={{ marginTop: 40 }}>
                  <div
                    style={{ marginLeft: 10, padding: 20, color: "#000000" }}
                  >
                    WEB APPLICATIONS
                  </div>
                </div>
                <div
                  style={{ marginTop: 30, marginLeft: 25, color: "#666666" }}
                >
                  <a
                    style={{ color: "#666666", textDecoration: "none" }}
                    href={`${basicUrl}/CustomWebApps`}
                  >
                    -Custom Web Apps
                  </a>
                </div>
                <div
                  style={{ marginTop: 30, marginLeft: 25, color: "#666666" }}
                >
                  <a
                    style={{ color: "#666666", textDecoration: "none" }}
                    href={`${basicUrl}/ProgressiveWebApps`}
                  >
                    -Progressive Web Apps
                  </a>
                </div>
                <div
                  style={{ marginTop: 30, marginLeft: 25, color: "#666666" }}
                >
                  <a
                    style={{ color: "#666666", textDecoration: "none" }}
                    href={`${basicUrl}/FrontEndServices`}
                  >
                    -Front End Development Services
                  </a>
                </div>
                <div style={{ marginTop: 40 }}>
                  <div
                    style={{ marginLeft: 10, padding: 20, color: "#000000" }}
                  >
                    PRODUCT ENGINEERING
                  </div>
                </div>

                <div
                  style={{
                    backgroundColor: "lightgray",
                    width: "100%",
                    height: 1,
                    marginTop: 10,
                    justifyContent: "center",
                    marginRight: 20,
                  }}
                ></div>
                <div
                  style={{ marginTop: 30, marginLeft: 25, color: "#666666" }}
                >
                  <a
                    style={{ color: "#666666", textDecoration: "none" }}
                    href={`${basicUrl}/SaaSDevelopment`}
                  >
                    -SaaS Application Development
                  </a>
                </div>
                <div
                  style={{ marginTop: 30, marginLeft: 25, color: "#666666" }}
                >
                  <a
                    style={{ color: "#666666", textDecoration: "none" }}
                    href={`${basicUrl}/LeanDevelopment`}
                  >
                    -Lean Product Development
                  </a>
                </div>
                <div
                  style={{ marginTop: 30, marginLeft: 25, color: "#666666" }}
                >
                  <a
                    style={{ color: "#666666", textDecoration: "none" }}
                    href={`${basicUrl}/CustomApplicationDevelopment`}
                  >
                    -Custom Application Development
                  </a>
                </div>
                <div style={{ marginTop: 40 }}>
                  <div
                    style={{ marginLeft: 10, padding: 20, color: "#000000" }}
                  >
                    TRENDING TECHNOLOGIES
                  </div>
                </div>

                <div
                  style={{
                    backgroundColor: "lightgray",
                    width: "100%",
                    height: 1,
                    marginTop: 10,
                    justifyContent: "center",
                    marginRight: 20,
                  }}
                ></div>
                <div
                  style={{ marginTop: 30, marginLeft: 25, color: "#666666" }}
                >
                  <a
                    style={{ color: "#666666", textDecoration: "none" }}
                    href={`${basicUrl}/iotDevelopment`}
                  >
                    -IOT Development
                  </a>
                </div>
                <div
                  style={{ marginTop: 30, marginLeft: 25, color: "#666666" }}
                >
                  <a
                    style={{ color: "#666666", textDecoration: "none" }}
                    href={`${basicUrl}/augmentedRealityDevelopment`}
                  >
                    -Augmented Reality App Development
                  </a>
                </div>
                <div
                  style={{ marginTop: 30, marginLeft: 25, color: "#666666" }}
                >
                  <a
                    style={{ color: "#666666", textDecoration: "none" }}
                    href={`${basicUrl}/chatbotDevelopment`}
                  >
                    -Chatbot Development
                  </a>
                </div>
              </div>
            )}

            <div
              style={{
                fontSize: 18,
                justifyContent: "space-between",
                marginRight: 10,
                display: "flex",
                alignItems: "center",
                marginTop: 13,
              }}
            >
              <div
                style={{
                  marginLeft: 10,
                }}
              >
                RESOURCES
              </div>
              {resourcesOpen ? (
                <div
                  style={{
                    backgroundColor: "#FE0701",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderRadius: 4,
                  }}
                >
                  <div
                    onClick={handleResourcesOpen}
                    style={{
                      color: "white",
                    }}
                  >
                    <ArrowDropUpIcon />
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "#FE0701",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderRadius: 4,
                  }}
                >
                  <div
                    onClick={handleResourcesClose}
                    style={{
                      color: "white",
                    }}
                  >
                    <ArrowDropDownOutlinedIcon />
                  </div>
                </div>
              )}
            </div>
            {resourcesOpen && (
              <div
                style={{ background: "#F6F6F6", width: "100%", height: "100%" }}
              >
                <div
                  style={{
                    marginLeft: 5,
                    padding: 10,
                    color: "#888888",
                    fontWeight: "bold",
                  }}
                >
                  <a
                    style={{ color: "#666666", textDecoration: "none" }}
                    href={`${basicUrl}/pressRelease`}
                  >
                    PRESS RELEASE
                  </a>
                </div>
              </div>
            )}

            <div
              onClick={() => navigation("/company")}
              style={{
                fontSize: 18,
                marginLeft: 10,
                marginRight: 10,
                marginTop: 13,
              }}
            >
              COMPANY
            </div>
            <div
              onClick={() => navigation("/technologies")}
              style={{
                fontSize: 18,
                marginLeft: 10,
                marginRight: 10,
                marginTop: 13,
              }}
            >
              TECHNOLOGIES
            </div>
            <div
              onClick={() => navigation("/contact")}
              style={{
                fontSize: 18,
                marginLeft: 10,
                marginRight: 10,
                marginTop: 13,
              }}
            >
              CONTACT
            </div>
          </div>
        </div>
      )}
      {anchorEl ? (
        <>
          <div
            onMouseLeave={handlePopoverClose}
            style={{
              position: "absolute",
              top: 334,
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: 500,
              width: "70vw",
              backgroundColor: "white",
              zIndex: 1,
              boxShadow: "1px 2px 9px gray",
            }}
          >
            <Box
              sx={{
                display: { md: "none", xl: "flex" },
                width: "100%",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    height: 100,
                    width: 110,
                    zIndex: 1,
                    marginTop: -100,
                    // marginLeft: -30,
                  }}
                />
              </div>
            </Box>
            <Box
              sx={{
                display: { md: "flex", xl: "none" },
                width: "100%",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    height: 80,
                    width: 140,
                    zIndex: 1,
                    marginTop: -70,
                    marginLeft: 80,
                    // backgroundColor: "red",
                  }}
                ></div>
              </div>
            </Box>
            <div style={{ display: "flex", height: "100%" }}>
              <div
                style={{
                  width: "105%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      background: "white",
                      padding: 20,
                      width: "75%",
                    }}
                  >
                    <div
                      style={{
                        marginTop: 10,
                        paddingBottom: 5,
                        borderBottom: "0.5px solid lightgray",
                      }}
                    >
                      APP DEVELOPMENT
                    </div>
                    <div
                      onClick={() => navigation("/androidAppDevelopment")}
                      style={{
                        marginTop: 30,
                        color: "black",
                        cursor: "pointer",
                      }}
                    >
                      -Android App Development
                    </div>
                    <div
                      onClick={() => navigation("/IOSAppDevelopment")}
                      style={{
                        marginTop: 10,
                        color: "black",
                        cursor: "pointer",
                      }}
                    >
                      -IOS App Development
                    </div>
                    <div
                      onClick={() => navigation("/CrossMobileDevelopment")}
                      style={{
                        marginTop: 10,
                        color: "black",
                        cursor: "pointer",
                      }}
                    >
                      -Cross Platform Mobile Development
                    </div>
                  </div>
                  <div
                    style={{
                      background: "white",
                      padding: 20,
                      width: "75%",
                    }}
                  >
                    <div
                      style={{
                        marginTop: 10,
                        paddingBottom: 5,
                        borderBottom: "0.5px solid lightgray",
                      }}
                    >
                      WEB APPLICATIONS
                    </div>
                    <div
                      onClick={() => navigation("/CustomWebApps")}
                      style={{
                        marginTop: 30,
                        color: "black",
                        cursor: "pointer",
                      }}
                    >
                      -Custom Web Apps
                    </div>
                    <div
                      onClick={() => navigation("/ProgressiveWebApps")}
                      style={{
                        marginTop: 10,
                        color: "black",
                        cursor: "pointer",
                      }}
                    >
                      -Progressive Web Apps
                    </div>
                    <div
                      onClick={() => navigation("/FrontEndServices")}
                      style={{
                        marginTop: 10,
                        color: "black",
                        cursor: "pointer",
                      }}
                    >
                      -Front End Development Services
                    </div>
                  </div>
                  <div
                    style={{
                      background: "white",
                      padding: 20,
                      width: "75%",
                    }}
                  >
                    <div
                      style={{
                        marginTop: 10,
                        paddingBottom: 5,
                        borderBottom: "0.5px solid lightgray",
                      }}
                    >
                      PRODUCT ENGINEERING
                    </div>
                    <div
                      onClick={() => navigation("/SaaSDevelopment")}
                      style={{
                        marginTop: 30,
                        color: "black",
                        cursor: "pointer",
                      }}
                    >
                      -SaaS Application Development
                    </div>
                    <div
                      onClick={() => navigation("/LeanDevelopment")}
                      style={{
                        marginTop: 10,
                        color: "black",
                        cursor: "pointer",
                      }}
                    >
                      -Lean Product Development
                    </div>
                    <div
                      onClick={() =>
                        navigation("/CustomApplicationDevelopment")
                      }
                      style={{
                        marginTop: 10,
                        color: "black",
                        cursor: "pointer",
                      }}
                    >
                      -Custom Application Development
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      background: "white",
                      padding: 20,
                    }}
                  >
                    <div
                      style={{
                        marginTop: 10,
                        paddingBottom: 5,
                        borderBottom: "0.5px solid lightgray",
                        width: 300,
                      }}
                    >
                      TRENDING TECHNOLOGIES
                    </div>
                    <div
                      onClick={() => navigation("/iotDevelopment")}
                      style={{ marginTop: 30, cursor: "pointer" }}
                    >
                      -IOT Development
                    </div>
                    <div
                      onClick={() => navigation("/augmentedRealityDevelopment")}
                      style={{ marginTop: 10, cursor: "pointer" }}
                    >
                      -Augmented Reality App Development
                    </div>
                    <div
                      onClick={() => navigation("/chatbotDevelopment")}
                      style={{ marginTop: 10, cursor: "pointer" }}
                    >
                      -Chatbot Development
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {anchorE2 ? (
        <div
          onMouseLeave={handlePopoverClose2}
          style={{
            position: "absolute",
            top: 108,
            left: "63%",
            transform: "translate(-50%, -50%)",
            height: 50,
            width: "15vw",
            backgroundColor: "white",
            zIndex: 1,
            boxShadow: "1px 2px 9px gray",
          }}
        >
          <Box
            sx={{
              display: { md: "none", xl: "flex" },
              width: "100%",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                // backgroundColor: "red",
              }}
            >
              <div
                style={{
                  height: 100,
                  width: 130,
                  zIndex: 1,
                  marginTop: -100,
                  marginLeft: -20,
                }}
              />
            </div>
          </Box>
          <Box
            sx={{
              display: { md: "flex", xl: "none" },
              width: "100%",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  height: 100,
                  width: 140,
                  zIndex: 1,
                  marginTop: -100,
                  marginLeft: -130,
                }}
              ></div>
            </div>
          </Box>

          <div
            onClick={() => navigation("/pressRelease")}
            className="pressRelease"
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              paddingLeft: 10,
            }}
          >
            PRESS RELEASE
          </div>
        </div>
      ) : null}

      <div
        className="mainHeader"
        style={{ backgroundColor: background ? "white" : "" }}
      >
        <div onClick={() => navigation("/")} className="logo">
          <img
            src={require("../assets/logo.png")}
            alt="logo"
            width={120}
            height={80}
          />
        </div>
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <div className="menuLinks">
            <div
              className="test"
              onClick={() => navigation("/projects")}
              style={{
                color: styling ? "black" : "white",
                paddingLeft: 20,
                fontSize: "medium",
                cursor: "pointer",
              }}
            >
              PROJECTS
            </div>
            <div
              className="test"
              onMouseEnter={handlePopoverOpen}
              onClick={handleOpenModal}
              style={{
                color: styling ? "black" : "white",
                paddingLeft: 20,
                fontSize: "medium",
                cursor: "pointer",
              }}
            >
              SERVICES
            </div>

            <div style={{ color: styling ? "black" : "white", marginTop: 5 }}>
              <KeyboardArrowDownOutlinedIcon />
            </div>

            <div
              className="test"
              onMouseEnter={handlePopoverOpen2}
              style={{
                color: styling ? "black" : "white",
                paddingLeft: 20,
                fontSize: "medium",
                cursor: "pointer",
              }}
            >
              RESOURCES
            </div>
            <div style={{ color: styling ? "black" : "white", marginTop: 5 }}>
              <KeyboardArrowDownOutlinedIcon />
            </div>
            <div
              className="test"
              onClick={() => navigation("/company")}
              style={{
                color: styling ? "black" : "white",

                paddingLeft: 20,
                fontSize: "medium",
                cursor: "pointer",
              }}
            >
              COMPANY
            </div>
            <div
              className="test"
              onClick={() => navigation("/technologies")}
              style={{
                color: styling ? "black" : "white",
                paddingLeft: 20,
                fontSize: "medium",
                cursor: "pointer",
              }}
            >
              TECHNOLOGIES
            </div>
            <div className="button">
              <div onClick={() => navigation("/contact")} className="contact">
                CONTACT
              </div>
            </div>
          </div>
        </Box>
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            width: "100%",
            flexDirection: "row-reverse",
            marginRight: 5,
          }}
        >
          {mobileOpen ? (
            <div onClick={handleMobileOpen}>
              <MenuIcon style={{ color: "black", fontSize: 25 }} />
            </div>
          ) : (
            <div onClick={handleMobileClose}>
              <MenuIcon style={{ color: "black", fontSize: 25 }} />
            </div>
          )}
        </Box>
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <div
            style={{
              left: 0,

              backgroundColor: "#fff",
            }}
          >
            <div></div>
          </div>
        </Box>
      </div>
    </>
  );
}

export default Navbar;
