import React from "react";
import "../styles/requestButton2.css";

import Box from "@mui/material/Box";
const RequestButton2 = ({ title }) => {
  return (
    <>
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <a className="request-button-2-web" href="#class">
          {title}
        </a>
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <a className="request-button-2-mobile" href="#class">
          {title}
        </a>
      </Box>
    </>
  );
};

export default RequestButton2;
