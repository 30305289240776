import { Grid } from "@mui/material";
import React from "react";
import Navbar from "../Components/Navbar";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import Footer from "../Components/Footer";
import MetaData from "../Components/MetaData";
import NavBarSec from "../Components/NavBar2";

function Company() {
  const responsive = window.innerWidth > 500;
  return (
    <>
      <MetaData title={"Bitsbay | Company"} />
      <NavBarSec />
      <div className="company_banner">
        <div className="company_banner_contaner">
          <div className="comapny_b_haeding">Building Apps for the future</div>
          <div className="comapnuy_b_text">
            Our amazing team is focused on developing apps which can leave a
            mark in the tech industry with future prospects in mind.
          </div>
        </div>
      </div>
      <div className="company_content_box">
        <div className="company_story_heaeding">Our Story</div>
        <div className="stroy_text">
          We at Bitsbay are focused in developing apps that let our clients
          stand out in front of their competitors. Our team started providing
          custom-built technology services in 2019 with a vision of building
          robust, innovative and trendsetting solutions.
        </div>
        <div className="stroy_text">
          Our app development team offers a universally integrated skill set,
          which results in the creation of fascinating user experience and
          sophisticated technology framework. We have enabled entrepreneurs,
          ecommerce experts, and financial businesses to build, improve and
          expand globally. Our app development process incorporates top-class
          technique, clean UX/UI structure, and follows Lean-Agile mindset.
        </div>
        <div className="company_story_heaeding2">Mission & Vision</div>
        <div className="stroy_text">
          To maintain the highest levels of quality services so that we can
          provide the best in the market software services. We take pride in
          enabling future markets with state-of-the-art digital solutions
        </div>
        <div className="company_story_heaeding2">Our Services</div>
        <div className="stroy_text">
          We provide bespoke web and mobile apps development along with fully
          customised software development services that lead companies to growth
          and sustainability. a proven record of delivering beautiful software
          products and consultancy, we are here to offer you services and
          strategies that drive innovation, build brand equity, and increase
          your revenue.
        </div>
      </div>

      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
        }}
        container
        md={12}
        xs={12}
      >
        <Grid
          style={{
            background: "white",
            border: "0.5px solid #E1E1E1",
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            padding: 20,
            paddingTop: 45,
            paddingBottom: 45,
            marginLeft: 10,
            marginRight: 10,
            marginBottom: 20,
          }}
          item
          md={1.5}
          xs={10}
        >
          <img
            width="75px"
            height="75px"
            src={require("../assets/mobilemain.png")}
          />
          <div
            style={{
              color: "#333333",
              fontSize: 17,
              fontWeight: 600,
            }}
          >
            Mobile Apps
          </div>
          <div
            style={{
              color: "#333333",
              fontSize: 17,
              fontWeight: 600,
            }}
          >
            Development
          </div>
        </Grid>
        <Grid
          style={{
            background: "white",
            border: "0.5px solid #E1E1E1",
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            padding: 20,
            paddingTop: 45,
            paddingBottom: 45,
            marginLeft: 10,
            marginRight: 10,
            marginBottom: 20,
          }}
          item
          md={1.5}
          xs={10}
        >
          <img
            width="75px"
            height="75px"
            src={require("../assets/laptop.png")}
          />
          <div
            style={{
              color: "#333333",
              fontSize: 17,
              fontWeight: 600,
            }}
          >
            Web Apps
          </div>
          <div
            style={{
              color: "#333333",
              fontSize: 17,
              fontWeight: 600,
            }}
          >
            Development
          </div>
        </Grid>
        <Grid
          style={{
            background: "white",
            border: "0.5px solid #E1E1E1",
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            padding: 20,
            paddingTop: 45,
            paddingBottom: 45,
            marginLeft: 10,
            marginRight: 10,
            marginBottom: 20,
          }}
          item
          md={1.5}
          xs={10}
        >
          <img
            width="75px"
            height="75px"
            src={require("../assets/cloud.png")}
          />
          <div
            style={{
              color: "#333333",
              fontSize: 17,
              fontWeight: 600,
            }}
          >
            SaaS
          </div>
          <div
            style={{
              color: "#333333",
              fontSize: 17,
              fontWeight: 600,
            }}
          >
            Development
          </div>
        </Grid>
        <Grid
          style={{
            background: "white",
            border: "0.5px solid #E1E1E1",
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            padding: 20,
            paddingTop: 45,
            paddingBottom: 45,
            marginLeft: 10,
            marginRight: 10,
            marginBottom: 20,
          }}
          item
          md={1.5}
          xs={10}
        >
          <img
            width="75px"
            height="75px"
            src={require("../assets/mobile.png")}
          />
          <div
            style={{
              color: "#333333",
              fontSize: 17,
              fontWeight: 600,
            }}
          >
            UI / UX
          </div>
          <div
            style={{
              color: "#333333",
              fontSize: 17,
              fontWeight: 600,
            }}
          >
            Design
          </div>
        </Grid>
        <Grid
          style={{
            background: "white",
            border: "0.5px solid #E1E1E1",
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            padding: 20,
            paddingTop: 45,
            paddingBottom: 45,
            marginLeft: 10,
            marginRight: 10,
            marginBottom: 20,
          }}
          item
          md={1.5}
          xs={10}
        >
          <img
            width="75px"
            height="75px"
            src={require("../assets/internet.png")}
          />
          <div
            style={{
              color: "#333333",
              fontSize: 17,
              fontWeight: 600,
            }}
          >
            iOT
          </div>
        </Grid>
        <Grid
          style={{
            background: "white",
            border: "0.5px solid #E1E1E1",
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            padding: 20,
            paddingTop: 45,
            paddingBottom: 45,
            marginLeft: 10,
            marginRight: 10,
            marginBottom: 20,
          }}
          item
          md={1.5}
          xs={10}
        >
          <img
            width="75px"
            height="75px"
            src={require("../assets/connect.png")}
          />
          <div
            style={{
              color: "#333333",
              fontSize: 17,
              fontWeight: 600,
            }}
          >
            Tech Staff
          </div>
          <div
            style={{
              color: "#333333",
              fontSize: 17,
              fontWeight: 600,
            }}
          >
            Augmentation
          </div>
        </Grid>
      </Grid>

      <Footer />
    </>
  );
}

export default Company;
