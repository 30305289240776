import React, { useEffect } from "react";
import NavBarSec from "../Components/NavBar2";
import Footer from "../Components/Footer";
import { useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules"; // Corrected import
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./projectdemo.css";
import theme from "../theme/color.json";

const ProjectsDemos = () => {
  const location = useLocation();
  const demos = location?.state;

  useEffect(() => {
    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
      .titles {
        color: ${theme?.textBlack || "#000"};
      }
      .description {
        color: ${theme?.textBlack || "#000"};
      }
    `;
    document.head.appendChild(styleElement);
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  console.log(demos);

  return (
    <>
      <NavBarSec />
      <div
        className="project_demo_banner"
        style={{ background: `url(${demos.banner})` }}
      >
        {demos?.banner_logo && (
          <img
            src={demos.banner_logo}
            alt={demos.alt || "Banner Logo"}
            className="project_banner_logo"
          />
        )}
      </div>
      <div className="project_demo_container">
        {demos?.hide && <div className="titles">About</div>}
        {demos?.projectDes && (
          <div className="description">{demos.projectDes}</div>
        )}

        {demos?.hide && <div className="titles">Tech Stack</div>}
        {demos?.projectDes3 && (
          <div className="description">{demos.projectDes3}</div>
        )}

        {demos?.hide && <div className="titles">Examples :</div>}
        <div className="carousel_container">
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={30}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            autoplay={{ delay: 3000 }}
            loop={true}
            className="project_carousel"
          >
            {demos?.img?.map((data, index) => (
              <SwiperSlide key={index}>
                <img
                  src={data.image || ""}
                  alt={`Project Image ${index + 1}`}
                  className="img-fluid"
                  style={{
                    height: 550,
                    borderRadius: "10px",
                    margin: "auto",
                    display: "block",
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProjectsDemos;
