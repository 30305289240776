import { Grid } from "@mui/material";
import React from "react";
import BuildAppSubmit from "../Components/BuildAppSubmit";
import FourCards from "../Components/FourCards";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import MetaData from "../Components/MetaData";
import RequestButton from "../Components/RequestButton";
import NavBarSec from "../Components/NavBar2";
import theme from "../theme/color.json";
function CrossMobileDevelopment() {
  const responsive = window.innerWidth > 500;
  const styling = true;

  return (
    <>
      <style>{dynamicCss}</style>
      <NavBarSec />
      <div style={{ textAlign: responsive ? "" : "center" }}>
        <MetaData
          title={
            "Take your Cross-Platform Mobile App Development to the Next Level"
          }
        />
        <Grid
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: 650
          }}
          container
          className="cross_development_header"
        >
          <Grid item xs={12} md={5}>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: responsive ? "" : "center",
                marginTop: responsive ? "" : 10
              }}
            >
              <div className="cross_development_header_text">
                <div
                  style={{
                    fontSize: responsive ? 40 : 25,
                    fontWeight: 700,
                    marginRight: responsive ? 0 : 10,
                    marginLeft: responsive ? 0 : 10
                  }}
                >
                  Take your Cross-Platform Mobile App Development to the Next
                  Level
                </div>
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    marginTop: 10,
                    marginRight: responsive ? 0 : 10,
                    marginLeft: responsive ? 0 : 10
                  }}
                >
                  Flawless Hybrid app development for multiple platforms using.
                  HTML5, CSS3 and JavaScript
                </div>
                <RequestButton />
              </div>
            </div>
          </Grid>
          <Grid item xs={10} md={5}>
            <div
              style={{
                marginTop: 30,
                display: "flex",
                flexDirection: "row-reverse",
                justifyContent: responsive ? "" : "center"
              }}
            >
              <img width="60%" src={require("../assets/CrossMobile.png")} />
            </div>
          </Grid>
        </Grid>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: 50
          }}
        >
          <div style={{ fontSize: 25, fontWeight: 700, marginBottom: 30 }}>
            Native vs. Cross-Platform Apps
          </div>
          <div
            style={{
              fontSize: 17,
              fontWeight: 600,
              marginLeft: responsive ? 0 : 10,
              marginRight: responsive ? 0 : 10
            }}
          >
            When it comes to cross platform development strategy there are two
            popular ways that most companies and developers choose from.
          </div>
        </div>
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 30
          }}
          container
          md={12}
        >
          <Grid
            style={{
              marginLeft: 10,
              marginRight: 10,
              marginTop: 10,
              marginBottom: 10
            }}
            item
            xs={12}
            md={5}
          >
            <div
              className="cross_development_main_card"
              style={{
                display: "flex",
                justifyContent: "Center",
                flexDirection: "column",

                padding: 20,
                borderRadius: 10,
                paddingTop: 60,
                paddingBottom: 60
              }}
            >
              <div
                style={{
                  color: "black",
                  fontWeight: 700,
                  fontWeight: 700,
                  fontSize: 20,
                  marginBottom: 20
                }}
              >
                Native
              </div>
              <div
                style={{
                  color: "black",
                  fontWeight: 700,
                  fontWeight: 500,
                  fontSize: 16
                }}
              >
                Native app development is a traditional way for developing apps,
                where each app is developed for a single platform. Native apps
                are faster and can function offline; however, they don’t work on
                all devices and platforms and usually take more time and
                resources.
              </div>
              <div
                style={{
                  display: responsive ? "" : "flex",
                  justifyContent: "center"
                }}
              >
                <a
                  href="#class"
                  style={{
                    border: "1px solid black",
                    width: 150,
                    paddingTop: 20,
                    paddingBottom: 20,
                    paddingRight: 10,
                    paddingLeft: 10,
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 40,
                    textDecoration: "none",
                    color: "black",
                    fontWeight: 700
                  }}
                >
                  Request Quote
                </a>
              </div>
            </div>
          </Grid>

          <Grid
            style={{
              marginLeft: 10,
              marginRight: 10,
              marginTop: 10,
              marginBottom: 10
            }}
            item
            xs={12}
            md={5}
          >
            <div
              className="cross_development_main_card2"
              style={{
                display: "flex",
                justifyContent: "Center",
                flexDirection: "column",

                padding: 20,
                borderRadius: 10,
                paddingTop: 50,
                paddingBottom: 45
              }}
            >
              <div
                style={{
                  fontSize: 20,
                  marginBottom: 20,
                  color: "black",
                  fontWeight: 700
                }}
              >
                Hybrid
              </div>
              <div style={{ color: "black", fontWeight: 600, fontSize: 16 }}>
                Apps like Ionic framework use web-based code that can run on
                many different devices using one codebase. Hybrid application
                allow businesses to release the same product across multiple
                operating systems at once; however, performance might suffer as
                it depends on internet connectivity.
              </div>
              <div
                style={{
                  display: responsive ? "" : "flex",
                  justifyContent: "center"
                }}
              >
                <a
                  href="#class"
                  style={{
                    border: "1px solid black",
                    width: 150,
                    paddingTop: 20,
                    paddingBottom: 20,
                    paddingRight: 10,
                    paddingLeft: 10,
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 40,
                    textDecoration: "none",
                    color: "black",
                    fontWeight: 700
                  }}
                >
                  Request Quote
                </a>
              </div>
            </div>
          </Grid>
        </Grid>

        <div
          className="cross_development_main"
          style={{ paddingBottom: responsive ? 40 :20 }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginLeft: 30,
              marginRight: 30,
              textAlign: "center",
              marginTop: 40
            }}
          >
            <div style={{ fontSize: 25, fontWeight: 700, marginTop: 30 }}>
              Our App Development Process
            </div>
            <div style={{ marginTop: 20 }}>
              We follow a well-defined process to create native mobile apps,
              from brainstorming to the final delivery. Our experts map out the
              requirements of your project and go through several rounds of
              discussion to ensure that all the bases are covered before the
              actual development starts.
            </div>
          </div>
          <FourCards
            styling
            card1="Collect all the requirements from the client because all iOS application development begins with understanding your needs. We create technical documents that outline the project features and functionalities."
            card2="The prototype building of the product is done next which is a critical step – to test the feasibility of your idea. We create wireframes for your iOS app to show how it will look on the mobile screen."
            card3="The use cases will help you outline and define the operations that need to be performed on the screens. Use cases are significant because they form the base of your iOS application architecture."
            card4="The next stage is designing, which consists of understanding each screen’s functionality, determining how it interacts with other screens, and what kind of data should be loaded in it."
            title1="Project Requirements"
            title2="Wireframing"
            title3="Use Cases"
            title4="UI Design"
            img1={require("../assets/project.png")}
            img2={require("../assets/Wireframing.png")}
            img3={require("../assets/use.png")}
            img4={require("../assets/UI.png")}
            style1={{
              padding: 10,
              margin: 5,
              borderRadius: 20,

              height: 360
            }}
          />

          <FourCards
            card1="Following the agile methodology, we create a project plan that outlines each iteration’s milestones, tasks, and deliverables. We make sure that all the features are built according to your requirements."
            card2="This is an iterative process where programming code is written for new features in every iteration. We make sure that you get your iOS application on the App Store from scratch to a running app as soon as possible."
            card3="Once the development is done, we start testing for bugs and errors. After a thorough clean-up process, we pass on the final version to you for approval. Your feedback is then incorporated into the next iteration of your project."
            card4="After you approve the app, we submit it to the apple store. Once it’s live on the store, several things need to be done, such as setting up a developer account with Apple, configuring your application in iTunes Connect, checking the iOS version, and more."
            title1="Project plan"
            title2="Development Phase"
            title3="Quality Assurance"
            title4="App Launch"
            img1={require("../assets/plan.png")}
            img2={require("../assets/Development.png")}
            img3={require("../assets/Quality.png")}
            img4={require("../assets/app.png")}
            style1={{
              padding: 10,
              margin: 5,
              borderRadius: 20,

              height: 380
            }}
          />
        </div>
        <BuildAppSubmit />
        <Footer />
      </div>
    </>
  );
}

export default CrossMobileDevelopment;
const dynamicCss = `
.cross_development_header{
  background:${theme.bgGreen}
}
.cross_development_header_text{
  color:${theme.textWhite}
}
.cross_development_main_card{
  background:${theme.bgLightGreen};
  color:${theme.textBlack}
}
.cross_development_main_card2{
  background:${theme.bgLightBlue};
  color:${theme.textBlack}
}
.cross_development_main{
  background:${theme.bgGray};
  color:${theme.textBlack}
}

`;
