import { Grid } from "@mui/material";
import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import NineCards from "../Components/NineCards";
import BuildAppSubmit from "../Components/BuildAppSubmit";
import MetaData from "../Components/MetaData";
import RequestButton from "../Components/RequestButton";
import RequestButton2 from "../Components/RequestButton2";
import NavBarSec from "../Components/NavBar2";

function CustomApplicationDevelopment() {
  const responsive = window.innerWidth > 500;
  return (
    <>
      <NavBarSec />
      <div style={{ textAlign: responsive ? "" : "center" }}>
        <MetaData
          title={
            "Need a CUSTOMIZED APP? - Create, curate and teach. That’s the way we code"
          }
        />
        <Grid
          md={12}
          style={{
            background: "#44748E",
            display: "flex",
            justifyContent: "center",
            minHeight: 650
          }}
          container
        >
          <Grid item xs={12} md={6}>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: responsive ? "" : 10
              }}
            >
              <div
                style={{
                  color: "white",
                  marginLeft: responsive ? 70 : "",
                  marginRight: responsive ? "" : 20
                }}
              >
                <div
                  style={{
                    fontSize: responsive ? 40 : 25,
                    fontWeight: 700,
                    marginRight: responsive ? 0 : 10,
                    marginLeft: responsive ? 0 : 10
                  }}
                >
                  Need a CUSTOMIZED APP? - Create, curate and teach. That’s the
                  way we code
                </div>

                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    marginTop: 10,
                    marginRight: responsive ? 0 : 10,
                    marginLeft: responsive ? 0 : 10
                  }}
                >
                  Bitsbay produces powerful custom apps especially built to
                  cater your business necessities.
                </div>
                <RequestButton />
              </div>
            </div>
          </Grid>
          <Grid item xs={10} md={5}>
            <div style={{ marginTop: 30 }}>
              <img
                width="100%"
                height="100%"
                src={require("../assets/Custom.png")}
              />
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            paddingLeft: 10,
            paddingRight: 10
          }}
        >
          <div
            style={{
              marginTop: 30
            }}
          >
            <div
              style={{
                marginTop: 15,
                fontWeight: 500
              }}
            >
              <div
                style={{
                  fontSize: 25,
                  fontWeight: 500
                }}
              >
                Meet Business Requirements With
              </div>
              <div
                style={{
                  fontSize: 25,
                  fontWeight: 650,
                  marginBottom: 20
                }}
              >
                Custom Application Development
              </div>
              <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                When off the shelf solutions do not cut it, custom application
                development rescues the day by creating a solution that
                perfectly suits your needs. Custom application development
                requires an in-depth comprehension of your thoughts, processes,
                data, and organizational goals. They help companies in meeting
                KPI requirements swiftly and more efficiently.
              </div>
            </div>
            <div
              style={{
                fontSize: 25,
                fontWeight: 650,

                marginTop: 30
              }}
            >
              Custom App Development At Bitsbay
            </div>
            <div style={{ marginTop: 20, paddingLeft: 10, paddingRight: 10 }}>
              Where many custom apps incur significant losses to businesses due
              to sudden glitches, we at Bitsbay avoid this issue by relentlessly
              test-running your application to guarantee that it stays
              utilitarian in unfavorable conditions. We create apps that are
              bound to rake in ROI along with accomplishing your professional
              goals.
            </div>
          </div>
          <RequestButton2 title="I need a customized solution. Let’s discuss" />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: 50,
          background: "#E0E1E3",
          paddingRight: 10,
          paddingLeft: 10
        }}
      >
        <div style={{ textAlign: responsive ? "" : "center" }}>
          <div style={{ fontSize: 25, fontWeight: 700 }}>
            Reasons to choose us
          </div>
          <div style={{ marginTop: 30, paddingLeft: 10, paddingRight: 10 }}>
            Our immaculate app development services and support has always
            guaranteed customer fulfillment. Here’s a list of things that set us
            apart from others in the market.
          </div>
        </div>
        <Grid container>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 70
            }}
            item
            xs={12}
            md={5}
          >
            <div>
              <img
                width="100%"
                height="auto"
                src={require("../assets/ReasonsImg.png")}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={7}>
            <div
              style={{
                display: responsive ? "" : "flex",
                justifyContent: responsive ? "" : "center",
                alignItems: responsive ? "" : "center",
                flexDirection: responsive ? "" : "column"
              }}
            >
              <div
                style={{
                  fontSize: responsive ? 17 : 14,
                  fontWeight: 400,
                  marginTop: 50
                }}
              >
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Specialized ability to deal with any dimension of complex to
                    basic app features.
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Rapid development kick-off delivered by top-class app and
                    software engineers
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Our reputation for being one of the best in the business due
                    to our extended ability in Mobile and Web
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Free of Cost consultancy on your App thought.
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Complete transparency from the start till the end.
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    A trustworthy and competent partnership even after the
                    development of your solution.
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Proven track record in both building new products and making
                    an ongoing custom application
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Expert project management services powered by Agile
                    methodologies
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>

                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Designing your app with our robust and proficient designing
                    pool in Mobile, Web, or HTML5.
                  </div>
                </div>
                <div
                  style={{
                    marginTop: 10,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Excellent management and transparent communication.
                  </div>
                </div>
              </div>
              <RequestButton2 title="Lets connect" />
            </div>
          </Grid>
        </Grid>
      </div>

      <NineCards
        t1="APP REQUIREMENTS
      "
        t2="APPLICATION SCOPE
      "
        t3="PROJECT PLAN
      "
        t4="WIREFRAMING AND PROTOTYPING
      "
        t5="UI DESIGN
      "
        t6="DEVELOPMENT STAGE
      "
        t7="QUALITY ASSURANCE
      "
        t8="APPLICATION DISPATCH
      "
        t9="MAINTENANCE &
      SUPPORT"
        d1="Our application development team collects the brief from the client. An analyst takes first-hand information to simplify processes

      "
        d2="A draft is made containing all features and insights about the application.

      "
        d3="App development is then broken down into various phases following the agile procedure while furnishing the application cost.

      "
        d4="After the endorsement from the client, the wireframing and model building is started.

      "
        d5="Next we move to the design development. of the approved wireframes.

      "
        d6="The application moves into the development stage where Agile philosophy is pursued religiously to develop a robust app for you.

      "
        d7="Quality testing is performed on the application from the beginning to the end.

      "
        d8="Once the application experiences all periods of improvement, it is delivered to the client and uploaded on the play store.

      "
        d9="Post app launch, we deliver maintenance, quality support and enhancement services as required.

      "
        img1={require("../assets/requirement.png")}
        img2={require("../assets/application.png")}
        img3={require("../assets/plan.png")}
        img4={require("../assets/Wireframing.png")}
        img5={require("../assets/design.png")}
        img6={require("../assets/stage.png")}
        img7={require("../assets/Quality.png")}
        img8={require("../assets/dispatch.png")}
        img9={require("../assets/maintenance.png")}
      />
      <div style={{ textAlign: responsive ? "" : "center" }}>
        <BuildAppSubmit />
        <Footer />
      </div>
    </>
  );
}

export default CustomApplicationDevelopment;
