import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import TechnologyStack from "../Components/TechnologyStack";
import Footer from "../Components/Footer";
import MetaData from "../Components/MetaData";
import NavBarSec from "../Components/NavBar2";
import theme from "../theme/color.json"
const data = [
  {
    img: require("../assets/ndk.png"),
    width: "110px",
    height: "45px",
  },
  {
    img: require("../assets/java.png"),
    width: "110px",
    height: "45px",
  },

  {
    img: require("../assets/xamarin.png"),
    width: "110px",
    height: "45px",
  },
  {
    img: require("../assets/reactN.jfif"),
    width: "120px",
    height: "35px",
  },
];
const data2 = [
  {
    img: require("../assets/objective.png"),
    width: "110px",
    height: "35px",
  },
  {
    img: require("../assets/angular.png"),
    width: "110px",
    height: "35px",
  },

  {
    img: require("../assets/ionic.png"),
    width: "100px",
    height: "35px",
  },
  {
    img: require("../assets/reactN.jfif"),
    width: "120px",
    height: "35px",
  },
];
const data3 = [
  {
    img: require("../assets/google.png"),
    width: "110px",
    height: "30px",
  },
  {
    img: require("../assets/unity.png"),
    width: "110px",
    height: "30px",
  },
  {
    img: require("../assets/vive.png"),
    width: "110px",
    height: "30px",
  },
];

const data5 = [
  {
    img: require("../assets/node.png"),
    width: "110px",
    height: "30px",
  },
  {
    img: require("../assets/python.png"),
    width: "110px",
    height: "35px",
  },
  {
    img: require("../assets/ibm.png"),
    width: "110px",
    height: "35px",
  },

  {
    img: require("../assets/amazon.png"),
    width: "110px",
    height: "35px",
  },
  {
    img: require("../assets/digital.png"),
    width: "120px",
    height: "30px",
  },
];
const data6 = [
  {
    img: require("../assets/swift.png"),
    width: "110px",
    height: "25px",
  },
  {
    img: require("../assets/Azure.png"),
    width: "110px",
    height: "30px",
  },
  {
    img: require("../assets/oculus.png"),
    width: "110px",
    height: "30px",
  },
];
const data7 = [
  {
    img: require("../assets/MySQL.webp"),
    width: "110px",
    height: "30px",
  },
  {
    img: require("../assets/mongo.png"),
    width: "110px",
    height: "30px",
  },
  {
    img: require("../assets/firbase.png"),
    width: "110px",
    height: "30px",
  },
];
function Technologies() {
  const [selected, setSelected] = useState("STAFF");
  const handleSelected = (value) => {
    setSelected(value);
  };
  const responsive = window.innerWidth > 500;

  return (
    <>
      <style>{dynamicCss}</style>
      <MetaData title={"Bitsbay | Technologies"} />

      <NavBarSec />
      <div className="technolgy_banner">
        <div className="banner_headings">Serving Technology Better</div>
      </div>
      <div className="technologies_wrapper">
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="technolgy_heading">Technologies</div>
            <div className="stroy_text">
              A growing team of technologists- we have earned a reputation in
              the small & medium sized companies with deep understanding of
              different
            </div>
            <div className="stroy_text">
              Programming languages and tools in the areas of design,
              development, and testing. Certified and experienced, our team
              combines technical know-how with industry best practices to create
              viable solutions.
            </div>
          </div>
        </div>
        <TechnologyStack data={data} title="Native Android" />
        <TechnologyStack data={data2} title="Web & Hybrid" />
        <TechnologyStack data={data3} title="Virtual & Mixed Reality" />

        <TechnologyStack
          data={data5}
          title="Server Technologies
"
        />
        <TechnologyStack data={data6} title="Testing" />
        <TechnologyStack data={data7} title="Database" />
      </div>

      <Footer />
    </>
  );
}

export default Technologies;
const dynamicCss = `
.banner_headings{
  color:${theme.textWhite}
}
`;