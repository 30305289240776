import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import Box from "@mui/material/Box";
import theme from "../theme/color.json";
function Footer() {
  const navigation = useNavigate();

  const basicUrl = "https://bitsbayltd.com";
  // const basicUrl = "http://localhost:3000";

  const currentYear = new Date().getFullYear();

  return (
    <>
      <style>{dynamicCss}</style>
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <div
          style={{
            width: "100%",
            marginTop: "150px",
            // backgroundColor:"red"
          }}
        >
          <div className="footer_main_box">
            <div>
              <div className="footer_point">Services</div>
              <div
                style={{
                  marginTop: 10,
                  cursor: "pointer",
                  fontSize: 16,
                }}
              >
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/androidAppDevelopment`}
                  >
                    Android App Development
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/IOSAppDevelopment`}
                  >
                    IOS App Development
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/CrossMobileDevelopment`}
                  >
                    Cross Platform Mobile Development
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/CustomWebApps`}
                  >
                    Custom Web Apps
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/ProgressiveWebApps`}
                  >
                    Progressive Web Apps
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/FrontEndServices`}
                  >
                    Front End Development Services
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/SaaSDevelopment`}
                  >
                    SaaS Application Development
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/LeanDevelopment`}
                  >
                    Lean Product Development
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/CustomApplicationDevelopment`}
                  >
                    Custom Application Development
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/iotDevelopment`}
                  >
                    IOT Development
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/augmentedRealityDevelopment`}
                  >
                    Augmented Reality App Development
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/chatbotDevelopment`}
                  >
                    Chatbot Development
                  </a>
                </div>
              </div>
            </div>

            <div>
              <div className="footer_point">Technologies</div>
              <div
                style={{
                  marginTop: 10,
                  cursor: "pointer",
                  fontSize: 16,

                  fontWeight: "400",
                }}
              >
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/technologies`}
                  >
                    Native Android
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/technologies`}
                  >
                    Web & Hybrid
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/technologies`}
                  >
                    Virtual & Mixed Reality
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/technologies`}
                  >
                    Server Technologies
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/technologies`}
                  >
                    Testing
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/technologies`}
                  >
                    Database
                  </a>
                </div>
              </div>
            </div>

            <div>
              <div className="footer_point">Company</div>
              <div
                style={{
                  marginTop: 10,
                  cursor: "pointer",
                  fontSize: 16,

                  fontWeight: "400",
                }}
              >
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/company`}
                  >
                    About
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/projects`}
                  >
                    Projects
                  </a>
                </div>
              </div>
            </div>

            <div>
              <div className="footer_point">Contact</div>
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <div
                  style={{ fontSize: 16, color: "#262E32", fontWeight: "bold" }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    Pakistan
                    <PhoneInTalkIcon />
                    <a
                      href="tel: +1 (904) 809-7098"
                      style={{
                        fontSize: 16,
                        color: "#000",
                        fontWeight: 400,
                        color: "black",
                        textDecoration: "none",
                      }}
                    >
                      +1 (904) 809-7098
                    </a>
                  </div>
                </div>
                <div
                  style={{
                    color: "#171717",
                    fontSize: 16,
                    fontWeight: "bold",
                    marginBottom: 5,
                  }}
                >
                  Multan
                </div>
                <div style={{ fontSize: 15, marginBottom: 2, color: "black" }}>
                  1st Floor, Zoom Square Plaza,Masoom Shah Road,
                </div>
                <div style={{ fontSize: 15, marginBottom: 2, color: "black" }}>
                  Multan, Pakistan 60000
                </div>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <div
                    style={{
                      fontSize: 16,
                      color: "#262E32",
                      fontWeight: "bold",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      USA
                      <PhoneInTalkIcon />
                      <a
                        href="tel:+1 956 3516004"
                        style={{
                          fontSize: 16,
                          color: "#000",
                          fontWeight: "400",
                          color: "black",
                          textDecoration: "none",
                        }}
                      >
                        +1 956 3516004
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    color: "#171717",
                    fontSize: 16,
                    fontWeight: "bold",
                    marginBottom: 5,
                  }}
                >
                  Florida
                </div>
                <div style={{ fontSize: 15, marginBottom: 2 }}>
                  2234 North Federal Hwy Ste 3184 Boca
                </div>
                <div style={{ fontSize: 15, marginBottom: 2 }}>
                  Raton, FL 33431
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              maxWidth: "1440px",
              margin: "auto",
              backgroundColor: "#F5F5F5",
              padding: "5px 20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>Privacy Policy Terms of Use</div>
            <div>@ {currentYear} All Rights Reserved Bitsbayltd.</div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 10,
              }}
            >
              <div
                style={{
                  marginRight: 15,
                  borderRadius: 30,
                  width: 30,
                  height: 30,
                  backgroundColor: "#0A0F20",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <a href="https://www.facebook.com/bitsbayltd" target="_blank">
                  <img
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                    width={20}
                    height={20}
                    src={require("../assets/facebook.png")}
                  />
                </a>
              </div>
              <div
                style={{
                  backgroundColor: "#0A0F20",
                  borderRadius: 30,
                  width: 30,
                  height: 30,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <a
                  href="https://twitter.com/Bitsbayltd?t=Wt2l60yUeKwRIlyrmcKb2Q&s=09"
                  target="_blank"
                >
                  <img
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                    width={20}
                    height={20}
                    src={require("../assets/twitter.png")}
                  />
                </a>
              </div>
              <div
                style={{
                  backgroundColor: "#0A0F20",
                  borderRadius: 30,
                  width: 30,
                  height: 30,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 15,
                }}
              >
                <a
                  href="https://www.linkedin.com/company/bitsbayltd/mycompany/?viewAsMember=true"
                  target="_blank"
                >
                  <img
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                    width={20}
                    height={20}
                    src={require("../assets/linkedin.png")}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </Box>

      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <div
          style={{
            marginTop: 0,
            width: "100%",
            textAlign: "center",
          }}
        >
          <div className="footer_main_box">
            <div>
              <div className="footer_point">Services</div>
              <div
                style={{
                  marginTop: 10,
                  cursor: "pointer",
                  fontSize: 16,
                }}
              >
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/androidAppDevelopment`}
                  >
                    Android App Development
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/IOSAppDevelopment`}
                  >
                    IOS App Development
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/CrossMobileDevelopment`}
                  >
                    Cross Platform Mobile Development
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/CustomWebApps`}
                  >
                    Custom Web Apps
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/ProgressiveWebApps`}
                  >
                    Progressive Web Apps
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/FrontEndServices`}
                  >
                    Front End Development Services
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/SaaSDevelopment`}
                  >
                    SaaS Application Development
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/LeanDevelopment`}
                  >
                    Lean Product Development
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/CustomApplicationDevelopment`}
                  >
                    Custom Application Development
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/iotDevelopment`}
                  >
                    IOT Development
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/augmentedRealityDevelopment`}
                  >
                    Augmented Reality App Development
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/chatbotDevelopment`}
                  >
                    Chatbot Development
                  </a>
                </div>
              </div>
            </div>

            <div>
              <div className="footer_point">Technologies</div>
              <div
                style={{
                  marginTop: 10,
                  cursor: "pointer",
                  fontSize: 16,

                  fontWeight: "400",
                }}
              >
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/technologies`}
                  >
                    Native Android
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/technologies`}
                  >
                    Web & Hybrid
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/technologies`}
                  >
                    Virtual & Mixed Reality
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/technologies`}
                  >
                    Server Technologies
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/technologies`}
                  >
                    Testing
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/technologies`}
                  >
                    Database
                  </a>
                </div>
              </div>
            </div>

            <div>
              <div className="footer_point">Company</div>
              <div
                style={{
                  marginTop: 10,
                  cursor: "pointer",
                  fontSize: 16,

                  fontWeight: "400",
                }}
              >
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/company`}
                  >
                    About
                  </a>
                </div>
                <div className="footer">
                  <a
                    style={{ textDecoration: "none" }}
                    href={`${basicUrl}/projects`}
                  >
                    Projects
                  </a>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 10,
                  }}
                >
                  <div
                    style={{
                      marginRight: 15,
                      borderRadius: 30,
                      width: 30,
                      height: 30,
                      backgroundColor: "#0A0F20",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <a
                      href="https://www.facebook.com/bitsbayltd"
                      target="_blank"
                    >
                      <img
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                        width={20}
                        height={20}
                        src={require("../assets/facebook.png")}
                      />
                    </a>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#0A0F20",
                      borderRadius: 30,
                      width: 30,
                      height: 30,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <a
                      href="https://twitter.com/Bitsbayltd?t=Wt2l60yUeKwRIlyrmcKb2Q&s=09"
                      target="_blank"
                    >
                      <img
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                        width={20}
                        height={20}
                        src={require("../assets/twitter.png")}
                      />
                    </a>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#0A0F20",
                      borderRadius: 30,
                      width: 30,
                      height: 30,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: 15,
                    }}
                  >
                    <a
                      href="https://www.linkedin.com/company/bitsbayltd/mycompany/?viewAsMember=true"
                      target="_blank"
                    >
                      <img
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                        width={20}
                        height={20}
                        src={require("../assets/linkedin.png")}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="footer_point">Contact</div>
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <div
                  style={{ fontSize: 16, color: "#262E32", fontWeight: "bold" }}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Pakistan
                    <PhoneInTalkIcon />
                    <a
                      href="tel: +1 (904) 809-7098"
                      style={{
                        fontSize: 16,
                        color: "#000",
                        fontWeight: 400,
                        color: "black",
                        textDecoration: "none",
                      }}
                    >
                      +1 (904) 809-7098
                    </a>
                  </div>
                </div>
                <div
                  style={{
                    color: "#171717",
                    fontSize: 16,
                    fontWeight: "bold",
                    marginBottom: 5,
                  }}
                >
                  Multan
                </div>
                <div style={{ fontSize: 15, marginBottom: 2, color: "black" }}>
                  1st Floor, Zoom Square Plaza,Masoom Shah Road,
                </div>
                <div style={{ fontSize: 15, marginBottom: 2, color: "black" }}>
                  Multan, Pakistan 60000
                </div>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <div
                    style={{
                      fontSize: 16,
                      color: "#262E32",
                      fontWeight: "bold",
                    }}
                  >
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      USA
                      <PhoneInTalkIcon />
                      <a
                        href="tel:+1 956 3516004"
                        style={{
                          fontSize: 16,
                          color: "#000",
                          fontWeight: "400",
                          color: "black",
                          textDecoration: "none",
                        }}
                      >
                        +1 956 3516004
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    color: "#171717",
                    fontSize: 16,
                    fontWeight: "bold",
                    marginBottom: 5,
                  }}
                >
                  Florida
                </div>
                <div style={{ fontSize: 15, marginBottom: 2, color: "black" }}>
                  2234 North Federal Hwy Ste 3184 Boca
                </div>
                <div style={{ fontSize: 15, marginBottom: 2, color: "black" }}>
                  Raton, FL 33431
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              maxWidth: "1440px",
              margin: "auto",
              backgroundColor: "#F5F5F5",
              padding: "8px 20px",
              // display: "flex",
              // justifyContent: "space-between",
              // alignItems: "center",
            }}
          >
            <div>Privacy Policy Terms of Use</div>
            <div>@ {currentYear} All Rights Reserved Bitsbayltd.</div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 10,
              }}
            >
              <div
                style={{
                  marginRight: 15,
                  borderRadius: 30,
                  width: 30,
                  height: 30,
                  backgroundColor: "#0A0F20",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <a href="https://www.facebook.com/bitsbayltd" target="_blank">
                  <img
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                    width={20}
                    height={20}
                    src={require("../assets/facebook.png")}
                  />
                </a>
              </div>
              <div
                style={{
                  backgroundColor: "#0A0F20",
                  borderRadius: 30,
                  width: 30,
                  height: 30,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <a
                  href="https://twitter.com/Bitsbayltd?t=Wt2l60yUeKwRIlyrmcKb2Q&s=09"
                  target="_blank"
                >
                  <img
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                    width={20}
                    height={20}
                    src={require("../assets/twitter.png")}
                  />
                </a>
              </div>
              <div
                style={{
                  backgroundColor: "#0A0F20",
                  borderRadius: 30,
                  width: 30,
                  height: 30,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 15,
                }}
              >
                <a
                  href="https://www.linkedin.com/company/bitsbayltd/mycompany/?viewAsMember=true"
                  target="_blank"
                >
                  <img
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                    width={20}
                    height={20}
                    src={require("../assets/linkedin.png")}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
}

export default Footer;
const dynamicCss = `
.footer_main_box{
  background:${theme.bgWhiteSmoke}
}
.footer_point{
  color:${theme.textBlack}
}
.footer_point div{
  color:${theme.textBlack}
}
.footer a{
  color:${theme.textBlack}
}
`;
