import { Grid } from "@mui/material";
import React from "react";
import Navbar from "../Components/Navbar";
import ThreeCards from "../Components/ThreeCards";
import BuildAppSubmit from "../Components/BuildAppSubmit";
import MetaData from "../Components/MetaData";
import RequestButton from "../Components/RequestButton";
import NavBarSec from "../Components/NavBar2";
import Footer from "../Components/Footer";

function FrontEndServices() {
  const responsive = window.innerWidth > 500;
  const styling = true;
  return (
    <>
      <NavBarSec />
      <div style={{ textAlign: responsive ? "" : "center" }}>
        <MetaData
          title={
            "Front End Development Services - Where imagination meets creativity"
          }
        />
        <Grid
          md={12}
          style={{
            backgroundColor: "#34634B",
            display: "flex",
            justifyContent: "center",
            minHeight: 650
          }}
          container
        >
          <Grid item xs={12} md={5}>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginTop: responsive ? "" : 33,
                bottom: responsive ? "" : 25
              }}
            >
              <div style={{ color: "white" }}>
                <div
                  style={{
                    fontSize: responsive ? 40 : 25,
                    fontWeight: 700,
                    marginRight: responsive ? 0 : 10,
                    marginLeft: responsive ? 0 : 10
                  }}
                >
                  Front End Development Services - Where imagination meets
                  creativity
                </div>
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    marginTop: 10,
                    marginRight: responsive ? 0 : 10,
                    marginLeft: responsive ? 0 : 10
                  }}
                >
                  Reliable and adaptable web app solutions without jeopardizing
                  your commercial objectives.
                </div>
                <RequestButton />
              </div>
            </div>
          </Grid>
          <Grid
            style={{
              backgroundColor: ""
            }}
            item
            xs={10}
            md={5}
          >
            <div style={{ marginTop: 150 }}>
              <img width="100%" src={require("../assets/FrontEnd.png")} />
            </div>
          </Grid>
        </Grid>

        <div style={{ backgroundColor: "#E0E1E3" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginLeft: 30,
              marginRight: 30
            }}
          >
            <div
              style={{
                fontSize: 25,
                fontWeight: 700,
                marginTop: 50
              }}
            >
              Our Front End Development Services
            </div>
          </div>
          <Grid
            style={{ display: "flex", justifyContent: "center", marginTop: 30 }}
            container
          >
            <Grid
              style={{
                backgroundColor: "white",
                marginRight: 20,
                marginLeft: 20,
                marginTop: 20
              }}
              item
              xs={12}
              md={3}
            >
              <div
                style={{
                  margin: 20,
                  marginTop: 60,
                  marginBottom: 60,
                  marginRight: 40,
                  marginLeft: 40
                }}
              >
                <div>
                  <img
                    width={50}
                    height={50}
                    src={require("../assets/React.png")}
                  />
                </div>
                <div style={{ fontSize: 25, fontWeight: 700, color: "black" }}>
                  ReactJS Front End Development
                </div>
                <div style={{ marginTop: 15, fontSize: 17 }}>
                  For front end development, features like efficiency,
                  scalability and convenience go hand in hand with ReactJS. Our
                  front end developers create robust frameworks for responsive
                  and content-rich web applications that are fast-loading,
                  extensible and reusable.
                </div>
              </div>
            </Grid>
            <Grid
              style={{
                backgroundColor: "white",
                marginRight: 20,
                marginLeft: 20,
                marginTop: 20
              }}
              item
              xs={12}
              md={3}
            >
              <div
                style={{
                  margin: 20,
                  marginTop: 60,
                  marginBottom: 60,
                  marginRight: 40,
                  marginLeft: 40
                }}
              >
                <div>
                  <img
                    width={50}
                    height={50}
                    src={require("../assets/AngularJS.png")}
                  />
                </div>
                <div style={{ fontSize: 25, fontWeight: 700, color: "black" }}>
                  AngularJS Front End Development
                </div>
                <div style={{ marginTop: 15, fontSize: 17 }}>
                  AngularJS! A powerful front end tool, allows developers to
                  utilize maximum features of Javascript while developing rich,
                  responsive, dynamic and scalable front end solutions. With
                  AngularJS our front end developers have grasped their grip to
                  compile seamless web and mobile apps in a short span of time.
                </div>
              </div>
            </Grid>
            <Grid
              style={{
                backgroundColor: "white",
                marginRight: 20,
                marginLeft: 20,
                marginTop: 20
              }}
              item
              xs={12}
              md={3}
            >
              <div
                style={{
                  margin: 20,
                  marginTop: 60,
                  marginBottom: 60,
                  marginRight: 40,
                  marginLeft: 40
                }}
              >
                <div>
                  <img
                    width={50}
                    height={50}
                    src={require("../assets/VueJS.png")}
                  />
                </div>
                <div style={{ fontSize: 25, fontWeight: 700, color: "black" }}>
                  VueJS Front End Development
                </div>
                <div style={{ marginTop: 15, fontSize: 17 }}>
                  VueJS comes second to none while choosing a framework for
                  front end development due to its easy syntax and robust
                  architecture. Our front end developers make the best use of
                  VueJS to build adaptable progressive web apps which deliver
                  the same feeling of UI/UX on every device.
                </div>
              </div>
            </Grid>
            {/* <div style={{ marginTop: 30 }}>
            <TwoCards
              title1="Post-Delivery Support and Maintenance"
              title2="Flexible Project Structuring"
              card1="We provide you with post-launch support and maintenance services that allow you to keep your apps up and running even after they are created on your Android phone."
              card2="We structure each project as per the client’s needs to ensure his satisfaction at every development step. To do this, we diversify our team to provide maximum coverage across all platforms and technologies."
            />
          </div> */}
          </Grid>
        </div>

        <div style={{ backgroundColor: "#E0E1E3" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginLeft: 30,
              marginRight: 30
            }}
          >
            <div style={{ fontSize: 25, fontWeight: 700, marginTop: 30 }}>
              Our Front End Development Process
            </div>
            <div style={{ marginTop: 20 }}>
              Each successive step takes you closer to your dream come true.
            </div>
          </div>
          <ThreeCards
            styling
            title1="Project Requirements"
            title2="Wireframing"
            title3="Use Cases"
            card1="Client Support team assigns one member to gather project requirements."
            card2="Next is wireframing and prototyping so that the client gets an idea of how the screens will appear."
            card3="Project features and functionality are drafted in a use case document.
          "
            style1={{
              marginTop: responsive ? 50 : 30,
              marginBottom: responsive ? 50 : 30,
              marginLeft: 50,
              marginRight: 50,
              borderRadius: 20,
              backgroundColor: styling ? "white" : "",
              padding: 10,
              minHeight: 220
            }}
            img1={require("../assets/project.png")}
            img2={require("../assets/Wireframing.png")}
            img3={require("../assets/use.png")}
          />
          <ThreeCards
            styling
            title1="Design"
            title2="Project plan"
            title3="Agile development"
            card1="Once the client approves the prototypes, we move on to design the application."
            card2="The project is managed via project management tools."
            card3="The project goes into development where our development team puts in their dedicated effort."
            img1={require("../assets/UI.png")}
            img2={require("../assets/plan.png")}
            img3={require("../assets/Development.png")}
            style1={{
              marginTop: responsive ? 50 : 30,
              marginBottom: responsive ? 50 : 30,
              marginLeft: 50,
              marginRight: 50,
              borderRadius: 20,
              backgroundColor: styling ? "white" : "",
              padding: 10,
              minHeight: 220
            }}
          />
          <ThreeCards
            styling
            title1="Testing & QA"
            title2="Release"
            title3="Maintenance & Support"
            card1="Manual & automated QA checks are run post the development phase."
            card2="The product is made live where intended users can access it."
            card3="Post app launch as per requirement maintenance, quality support and enhancement services are delivered."
            img1={require("../assets/testing.png")}
            img2={require("../assets/release.png")}
            img3={require("../assets/maintenance.png")}
            style1={{
              marginTop: responsive ? 50 : 30,
              marginBottom: responsive ? 50 : 30,
              marginLeft: 50,
              marginRight: 50,
              borderRadius: 20,
              backgroundColor: styling ? "white" : "",
              padding: 10,
              minHeight: 220
            }}
          />
        </div>

        <BuildAppSubmit />
        <Footer />
      </div>
    </>
  );
}

export default FrontEndServices;
