import logo1 from "../assets/logo1.PNG";
import logo2 from "../assets/logo2.jpeg";
import logo3 from "../assets/logo3.PNG";
import logo4 from "../assets/logo4.PNG";
import logo5 from "../assets/logo5.png";
import logo6 from "../assets/logo6.png";
import courierLabelLogo from "../assets/courierLabel-logo.png";
import eCommSync_logo from "../assets/eCommSync_logo.png";
import eCommSync_1 from "../assets/eCommSync_1.png";
import eCommSync_2 from "../assets/eCommSync_2.png";
import eCommSync_3 from "../assets/eCommSync_3.png";
import eCommSync_4 from "../assets/eCommSync_4.png";
import sku_logo from "../assets/sku_logo.png";
import sku_1 from "../assets/sku_1.png";
import sku_2 from "../assets/sku_2.png";
import a5_logo from "../assets/a5_logo.png";
import a5_1 from "../assets/a5_1.png";
import a5_2 from "../assets/a5_2.png";
import a5_3 from "../assets/a5_3.png";
import pictureLogo from "../assets/picture_logo.png";
import picture_1 from "../assets/picture_1.png";
import picture_2 from "../assets/picture_2.png";
import picture_3 from "../assets/picture_3.png";
import picture_4 from "../assets/picture_4.png";
import bassimart from "../assets/bassimart.jpeg";
import courier_1 from "../assets/courier_1.png";
import courier_2 from "../assets/courier_2.png";
import courier_3 from "../assets/courier_3.png";
import courier_4 from "../assets/courier_4.png";
import courier_banner from "../assets/courier_banner.png";
import courier_banner_logo from "../assets/courier_banner_logo.png";
import basiimart2 from "../assets/basiimart2.jpeg";
import bassimart4 from "../assets/bassimart4.jpeg";
import bassimart1 from "../assets/bassimart1.jpeg";
import taski from "../assets/taski.jfif";
import taski1 from "../assets/taski1.jpeg";
import taski2 from "../assets/taski2.jpeg";
import taski3 from "../assets/taski3.jpeg";
import taski4 from "../assets/taski4.jpeg";
import teamImg from "../assets/connection.jfif";
import novoImg from "../assets/novo.jpeg";
import novo_1 from "../assets/novo_1.png";
import novo_2 from "../assets/novo_2.png";
import novo_3 from "../assets/novo_3.png";
import novo_4 from "../assets/novo_4.png";
import ss1 from "../assets/ss1.jpg";
import ss2 from "../assets/ss2.PNG";
import ss3 from "../assets/ss3.PNG";
import nn1 from "../assets/nn1.PNG";
import nn2 from "../assets/nn2.PNG";
import nn3 from "../assets/nn3.PNG";
import sized_banner from "../assets/sized_banner.png";
import sized_logo from "../assets/sized_logo.png";
import basimart_banner from "../assets/basimart_banner.png";
import basimart_banner_logo from "../assets/basimart_banner_logo.png";
import eCommSync_banner from "../assets/eCommSync_banner.png";
import eCommSync_banner_logo from "../assets/eCommSync_banner_logo.png";
import pic_banner from "../assets/pic_banner.png";
import pic_banner_logo from "../assets/pic_banner_logo.png";
import a5_banner from "../assets/a5_banner.png";
import a5_banner_logo from "../assets/a5_banner_logo.png";
import sku_banner from "../assets/sku_banner.png";
import sku_banner_logo from "../assets/sku_banner_logo.png";
import novo_banner from "../assets/novo_banner.png";
import novo_banner_logo from "../assets/novo_banner_logo.png";
import taskii_banner from "../assets/taskii_banner.png";
import taskii_banner_logo from "../assets/taskii_banner_logo.png";
import con_banner from "../assets/con_banner.png";
import con_banner_logo from "../assets/con_banner_logo.png";
import music_banner from "../assets/music_banner.png";
import music_banner_logo from "../assets/music_banner_logo.png";

const projectData = {
  webApps: [
    {
      data: {
        hide: true,
        projectDes:
          "PlaySiezd is a gaming e-commerce site where you can buy items by participating in games. Entry charges fluctuate based on the market value of goods. Earn credits by playing games and use them to purchase items.",
        projectDes3: "React.js, Firebase, Firestore",
        img: [{ image: ss1 }, { image: ss2 }, { image: ss3 }],
        banner: sized_banner,
        banner_logo: sized_logo,
      },
      logo: logo2,
      alt: "PlaySiezd Logo",
    },
    {
      data: {
        hide: true,
        projectDes:
          "Basimart makes it easy to order from your favorite stores. Shop items from more than 500 retailers and trusted local grocers across Costa Rica.",
        projectDes2:
          "Basimart connects you with a personal shopper to shop and deliver your order.",
        projectDes3: "React.js, MongoDB, Google Firebase, Node.js, Cloudinary",
        img: [
          { image: basiimart2 },
          { image: bassimart },
          { image: bassimart1 },
          { image: bassimart4 },
        ],
        banner: basimart_banner,
        banner_logo: basimart_banner_logo,
      },
      logo: logo5,
      alt: "Basimart Logo",
    },
    {
      data: {
        hide: true,
        projectDes:
          "Unlock special shipping discounts and streamline your order processing with a tailored service combination that propels your business forward with efficiency and savings.",
        // projectDes2:
        //   "Basimart connects you with a personal shopper to shop and deliver your order.",
        projectDes3: "React.js, MongoDB,  Node.js, express.js",
        img: [
          { image: courier_1 },
          { image: courier_2 },
          { image: courier_3 },
          { image: courier_4 },
        ],
        banner: courier_banner,
        banner_logo: courier_banner_logo,
      },
      logo: courierLabelLogo,
      alt: "Courier Label Logo",
    },
    {
      data: {
        hide: true,
        projectDes:
          "Ecom Sync simplifies inventory management across multiple e-commerce platforms like Amazon and eBay. Easily assign stores to your product listings,update stock quantities, and track delivered items in real time. With centralized control, you can prevent overselling, manage inventory efficiently, and ensure seamless operations. Stay organized and focus on growing your business with Ecom Sync!",
        projectDes3: "React.js, MongoDB, MERN Stack, Node.js ",
        img: [
          { image: eCommSync_1 },
          { image: eCommSync_2 },
          { image: eCommSync_3 },
          { image: eCommSync_4 },
        ],
        banner: eCommSync_banner,
        banner_logo: eCommSync_banner_logo,
      },
      logo: eCommSync_logo,
      alt: "eCommSync Logo",
    },
    {
      data: {
        hide: true,
        projectDes:
          "Picture on Gold Automate managing new orders for personalized name-plated jewelry products, including downloading content, printing invoices, retrieving customization details, and generating CSV files. This ensures efficient order processing and a streamlined workflow. Additionally, it can integrate to simplify order management across platforms like Amazon by retrieving orders, processing custom data, organizing files, and printing images or text. The tool will also generate invoices and shipping labels, ensuring efficient, accurate fulfillment.",
        projectDes3: ".net, My sql, Aws, Node.js, React",
        img: [
          { image: picture_1 },
          { image: picture_2 },
          { image: picture_3 },
          { image: picture_4 },
        ],
        banner: pic_banner,
        banner_logo: pic_banner_logo,
      },
      logo: pictureLogo,
      alt: "Picture on golg Logo",
    },
    {
      data: {
        hide: true,
        projectDes:
          "Sku Snap: Seamless Integration with Leading Distributors Sku Snap is a cutting-edge solution integrated with major distributors like Ingram Micro and Synnex, allowing you to create and manage orders effortlessly.",
        projectDes2:
          "Ingram Micro is a global leader in IT distribution, providing a wide range of technology products, solutions, and services to businesses. It helps companies access hardware, software, and cloud solutions from top brands.",
        projectDes_3:
          "Synnex is a leading distributor of IT products and services, specializing in supply chain management, logistics, and distribution for businesses worldwide.",
        projectDes_4:
          " By connecting with these distributors, Sku Snap streamlines your supply chain processes, making it easier to manage inventory, place orders, and maintain efficiency in your operations.",
        projectDes3: "React.js, MongoDB, MERN Stack, Node.js",
        img: [{ image: sku_1 }, { image: sku_2 }],
        banner: sku_banner,
        banner_logo: sku_banner_logo,
      },
      logo: sku_logo,

      alt: "eCommSync Logo",
    },
    {
      data: {
        hide: true,
        projectDes:
          "A5 is an integrated e-commerce platform that connects your stores, including Amazon, eBay, and Walmart, into one seamless system. Get real-time shipping rates, create shipping labels, and stay updated with order tracking.It also integrates with distributors like Ingram and Synnex, enabling you to easily place and manage orders. Simplify your operations and grow your business with A5!",
        projectDes3: "React.js, MongoDB, MERN Stack, Node.js",
        img: [{ image: a5_1 }, { image: a5_2 }, { image: a5_3 }],
        banner: a5_banner,
        banner_logo: a5_banner_logo,
      },
      logo: a5_logo,
      alt: "eCommSync Logo",
    },
  ],
  mobileApps: [
    {
      data: {
        hide: true,
        projectDes:
          "Novaapka is a shopping mall app where users earn prizes by spending a certain amount of money on items. Users can use points to purchase items.",
        projectDes2:
          "Bitsbay's responsibility was to build this app from prototype to store release, achieving client satisfaction.",
        projectDes3: "React Native, Firebase, Cloud Functions",
        img: [
          { image: novoImg },
          { image: novo_1 },
          { image: novo_2 },
          { image: novo_3 },
          { image: novo_4 },
        ],
        banner: novo_banner,
        banner_logo: novo_banner_logo,
      },
      logo: logo1,
      alt: "Novaapka Logo",
    },
    {
      data: {
        hide: true,
        projectDes:
          "Taskii is a task management system for creating tasks (electrician, plumber, service). It follows a creator-and-doer framework, allowing recurring and time-bound tasks. Both creators and doers can review each other.",
        projectDes3:
          "React/React Native, MongoDB, Google Firebase, GraphQL, Node.js",
        img: [
          { image: taski },
          { image: taski1 },
          { image: taski2 },
          { image: taski3 },
          { image: taski4 },
        ],
        banner: taskii_banner,
        banner_logo: taskii_banner_logo,
      },
      logo: logo6,
      alt: "Taskii Logo",
    },
    {
      data: {
        hide: true,
        projectDes:
          "Teamconnection is a task management app for an oil refinery firm. Companies can post news, assign tasks, and employees can view news and check tasks assigned to them.",
        projectDes3:
          "Mobile: React Native, Firebase, Firestore | Web: React.js, Firebase, Firestore",
        img: [{ image: teamImg }],
        banner: con_banner,
        banner_logo: con_banner_logo,
      },
      logo: logo3,
      alt: "Teamconnection Logo",
    },
    {
      data: {
        hide: false,
        img: [{ image: nn1 }, { image: nn2 }, { image: nn3 }],
        banner: music_banner,
        banner_logo: music_banner_logo,
      },
      logo: logo4,
      alt: "Music App Logo",
    },
  ],
};

export default projectData;
