import React from "react";
import { Link } from "react-router-dom";
import "./project.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import theme from "../../theme/color.json";
import projectData from "../../data/projectData";
// Assumed project data in a separate file

const ProjectsSection = () => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Click me for more details
    </Tooltip>
  );

  const ProjectItem = ({ link, state, imgSrc, imgAlt }) => (
    <Link className="link_type" to={link} state={state}>
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 200 }}
        overlay={renderTooltip}
      >
        <div className="portfolio_items shadow">
          <img src={imgSrc} alt={imgAlt} className="img-fluid logo_ss" />
        </div>
      </OverlayTrigger>
    </Link>
  );

  return (
    <>
      <style>{dynamicCss}</style>
      <div className="portfolio9_wrapper">
        <div className="portflio_left">
          <div className="portfolio_heading">Web Application</div>
          <div className="portfilio_box">
            {projectData.webApps.map((project, index) => (
              <ProjectItem
                key={index}
                link="/project-demo"
                state={project.data}
                imgSrc={project.logo}
                imgAlt={project.alt}
              />
            ))}
          </div>
        </div>
        <div className="portfolio_seprator">
          <div className="seprate_point1"></div>
          <div className="seprate_point2"></div>
        </div>
        <div className="portflio_left">
          <div className="portfolio_heading">Mobile Application</div>
          <div className="portfilio_box">
            {projectData.mobileApps.map((project, index) => (
              <ProjectItem
                key={index}
                link="/project-demo"
                state={project.data}
                imgSrc={project.logo}
                imgAlt={project.alt}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectsSection;

const dynamicCss = `
.portfolio_heading {
  color: ${theme.textRed};
}
.portfolio_items:hover {
  border-color: ${theme.borderSkyBlue};
}
.portfolio_seprator {
  border-color: ${theme.borderRed};
}
.seprate_point1, .seprate_point2 {
  background: ${theme.bgRed};
}
`;
