import Navbar from "../Components/Navbar";
import React from "react";
import { Grid } from "@mui/material";
import Footer from "../Components/Footer";
import BuildAppSubmit from "../Components/BuildAppSubmit";
import ThreeCards from "../Components/ThreeCards";
import TwoCards from "../Components/TwoCards";
import MetaData from "../Components/MetaData";
import RequestButton from "../Components/RequestButton";
import NavBarSec from "../Components/NavBar2";
import theme from "../theme/color.json";
function IOSAppDevelopment() {
  const responsive = window.innerWidth > 500;
  const styling = true;

  return (
    <>
      <style>{dynamicCss}</style>
      <NavBarSec />
      <div style={{ textAlign: responsive ? "" : "center" }}>
        <MetaData
          title={
            "Enhance Your capabilities with our iOS App Development Services"
          }
        />
        <Grid
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: 650
          }}
          container
          className="ios_development_header"
        >
          <Grid item xs={12} md={5}>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginTop: responsive ? "" : 10
              }}
            >
              <div className="ios_development_header_text">
                <div
                  style={{
                    fontSize: responsive ? 40 : 25,
                    fontWeight: 700,
                    marginRight: responsive ? 0 : 10,
                    marginLeft: responsive ? 0 : 10
                  }}
                >
                  Enhance Your capabilities with our iOS App Development
                  Services
                </div>

                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    marginTop: 10,
                    marginRight: responsive ? 0 : 10,
                    marginLeft: responsive ? 0 : 10
                  }}
                >
                  Elevate Your Business with Premium iOS Applications Built
                  Using Swift & Objective C.
                </div>
                <RequestButton />
              </div>
            </div>
          </Grid>
          <Grid item xs={10} md={5}>
            <div
              style={{
                marginTop: 30,
                display: "flex",
                justifyContent: responsive ? "flex-end" : "center"
              }}
            >
              <img width="45%" src={require("../assets/ios.png")} />
            </div>
          </Grid>
        </Grid>

        <div
          className="ios_development_main"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: 50
          }}
        >
          <Grid container md={12} style={{ paddingTop: 50, paddingBottom: 50 }}>
            <Grid item md={1}></Grid>
            <Grid md={10}>
              <div style={{ fontSize: 25, fontWeight: 700 }}>
                Hire Experienced, On-Demand iOS Developers (iPhone app
                developers)
              </div>
              <div
                style={{
                  marginTop: 20,
                  marginRight: responsive ? 0 : 10,
                  marginLeft: responsive ? 0 : 10
                }}
              >
                Without going through the hassle of managing a team on-site, you
                can access experts who have the skills and expertise needed for
                iOS application development. If you need a solo experienced
                developer, our experts are available as full-time employees or
                freelance, so you get a scalable workforce that is suited to
                your project’s requirements.
              </div>
            </Grid>
          </Grid>
          <TwoCards
            title1="Fixed Price Model"
            title2="Fixed Cost"
            card1="Let’s check out our pricing model for custom iOS app development. We have three pricing plans."
            card2="Applications where people share a scope, and we deliver at a fixed cost to you. You are not charged any additional fee based on increasing or decreasing the area after the application is provided to you! With this pricing model, you are sure to have the cost estimated before your project begins."
            img1={require("../assets/Fixed.png")}
            img2={require("../assets/Cost.png")}
          />

          <TwoCards
            title1="Time & Material Based"
            title2="Monthly Plan"
            card1="Our second model is time-based work. We estimate work requirements, and the client pays on the hourly rate agreed. We negotiate the hourly rate with our clients based on their budget and the features they want to be delivered on time! This is useful for apps where the requirements are not fixed at first."
            card2="If you need a hands-off approach, we have a monthly plan. You get the time and support related to your project without any obligation! It’s like hiring an outsourced team every month."
            img1={require("../assets/Material.png")}
            img2={require("../assets/Monthly.png")}
          />
        </div>
        <div
      
          className="gradient ios_development_main2"
          style={{ paddingBottom: responsive ? 40 : 20, }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginLeft: 30,
              marginRight: 30,
              textAlign: "center"
            }}
          >
            <div style={{ fontSize: 25, fontWeight: 700, marginTop: 30 }}>
              Our iOS App Development Process
            </div>
            <div style={{ marginTop: 20 }}>
              We follow a well-defined process to create iOS apps, from
              brainstorming to the final delivery. Our experts map out the
              requirements of your project and go through several rounds of
              discussion to ensure that all the bases are covered before the
              actual development starts.
            </div>
          </div>
          <ThreeCards
            styling
            title1="Project Requirements"
            title2="Wireframing"
            title3="Use Cases"
            card1="Collect all the requirements from the client because all iOS application development begins with understanding your needs. We create technical documents that outline the project features and functionalities."
            card2="The prototype building of the product is done next which is a critical step – to test the feasibility of your idea. We create wireframes for your iOS app to show how it will look on the mobile screen."
            card3="The use cases will help you outline and define the operations that need to be performed on the screens. Use cases are significant because they form the base of your iOS application architecture."
            img1={require("../assets/project.png")}
            img2={require("../assets/Wireframing.png")}
            img3={require("../assets/use.png")}
            style1={{
              margin: 12,
              borderRadius: 20,
              backgroundColor: styling ? "white" : "",
              padding: 10,
              height: 280,
              marginLeft: 30,
              marginRight: 30
            }}
          />
          <ThreeCards
            styling
            title1="UI Design"
            title2="Project plan"
            title3="Development Phase"
            card1="The next stage is designing, which consists of understanding each screen’s functionality, determining how it interacts with other screens, and what kind of data should be loaded in it."
            card2="Following the agile methodology, we create a project plan that outlines each iteration’s milestones, tasks, and deliverables. We make sure that all the features are built according to your requirements."
            card3="This is an iterative process where programming code is written for new features in every iteration. We make sure that you get your iOS application on the App Store from scratch to a running app as soon as possible."
            img1={require("../assets/UI.png")}
            img2={require("../assets/plan.png")}
            img3={require("../assets/Development.png")}
            style1={{
              margin: 12,
              borderRadius: 20,
              backgroundColor: styling ? "white" : "",
              padding: 10,
              height: 280,
              marginLeft: 30,
              marginRight: 30
            }}
          />
          <TwoCards
            title1="Quality Assurance"
            title2="App Launch"
            card1="Once the development is done, we start testing for bugs and errors. After a thorough clean-up process, we pass on the final version to you for approval. Your feedback is then incorporated into the next iteration of your project."
            card2="After you approve the app, we submit it to the apple store. Once it’s live on the store, several things need to be done, such as setting up a developer account with Apple, configuring your application in iTunes Connect, checking the iOS version, and more."
            img1={require("../assets/Quality.png")}
            img2={require("../assets/app.png")}
          />
        </div>

        <BuildAppSubmit />
        <Footer />
      </div>
    </>
  );
}

export default IOSAppDevelopment;
const dynamicCss = `
.ios_development_header{
  background:${theme.bgPurple}
}
.ios_development_header_text{
  color:${theme.textWhite}
}
.ios_development_main{
  background:${theme.bgGray};
  color:${theme.textBlack}
}
.ios_development_main2{
 
  color:${theme.textBlack}
}
`;