import React from "react";
import NavBarSec from "../NavBar2";
import "./blogDeatils.css";
import { Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Footer from "../Footer";

function BlogDetails({}) {
  const location = useLocation();
  const params = location.state;
  console.log(location, "location");
  const navigation = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <NavBarSec />
        <div className="blog_banner">
          <div className="blog_types">
            <div className="blog_b_haeding">
              BLOG / APP TRENDS 
              {/* {params?.heading}{" "} */}
            </div>
          </div>
          <div style={{ paddingTop: 10 }}>
            <div className="blog_b_text">{params?.text1} </div>
            <div className="blog_b_text">{params?.text2} </div>
            <div className="blog_b_text">{params?.text3}</div>
          </div>
          <div className="current_date">OCTOBER 31, 2022 |</div>

          <div className="hash_tag">
            <div style={{ paddingLeft: 10 }}># Android App Development</div>
            <div style={{ paddingLeft: 20 }}># Mobile App</div>
          </div>
        </div>
        <Grid container md={12} xs={12} className="blog_terms_wrapper_left">
          <Grid style={{ padding: 10 }} item md={7} xs={12}>
            <div>
              <div className="desc">{params?.desc}</div>
              <div className="blog_img">
                <img width="100%" src={params?.blogImg} />
              </div>
              <div className="title">{params?.title1}</div>
              <div className="desc">{params?.desc1}</div>

              <div className="desc">{params?.titleDes5}</div>
               <div className="desc">{params?.titleDes6}</div>
               <div className="desc">{params?.titleDes7}</div>
               <div className="desc">{params?.titleDes8}</div>
               <div className="desc">{params?.titleDes9}</div>

              {params?.blogImg1 && (
                <div className="blog_img">
                  <img width="100%" src={params?.blogImg1} />
                </div>
              )}
              <div className="title">{params?.title2}</div>
              <div className="desc">{params?.desc2}</div>

               <div className="desc">{params?.titleDes}</div>
               <div className="desc">{params?.titleDes1}</div>
               <div className="desc">{params?.titleDes2}</div>
               <div className="desc">{params?.titleDes3}</div>

              {params?.blogImg2 && (
                <div className="blog_img">
                  <img width="100%" src={params?.blogImg2} />
                </div>
              )}
              <div className="title">{params?.title3}</div>
              <div className="desc">{params?.desc3}</div>
              {params?.blogImg3 && (
                <div className="blog_img">
                  <img width="100%" src={params?.blogImg3} />
                </div>
              )}
              <div className="title">{params?.title4}</div>
              <div className="desc">{params?.desc4}</div>
              {params?.blogImg4 && (
                <div className="blog_img">
                  <img width="100%" src={params?.blogImg4} />
                </div>
              )}
              <div className="title">{params?.title5}</div>
              <div className="desc">{params?.desc5}</div>
              {params?.blogImg5 && (
                <div className="blog_img">
                  <img width="100%" src={params?.blogImg5} />
                </div>
              )}
              <div className="title">{params?.title6}</div>
              <div className="desc">{params?.desc6}</div>
              {params?.blogImg6 && (
                <div className="blog_img">
                  <img width="100%" src={params?.blogImg6} />
                </div>
              )}
              <div className="title">{params?.title7}</div>
              <div className="desc">{params?.desc7}</div>
              {params?.blogImg7 && (
                <div className="blog_img">
                  <img width="100%" src={params?.blogImg7} />
                </div>
              )}
              <div className="title">{params?.title8}</div>
              <div className="desc">{params?.desc8}</div>
              {params?.blogImg8 && (
                <div className="blog_img">
                  <img width="100%" src={params?.blogImg8} />
                </div>
              )}
              <div className="title">{params?.title9}</div>
              <div className="desc">{params?.desc9}</div>
              {params?.blogImg9 && (
                <div className="blog_img">
                  <img width="100%" src={params?.blogImg9} />
                </div>
              )}
              <div className="title">{params?.title10}</div>
              <div className="desc">{params?.desc10}</div>
              {params?.blogImg10 && (
                <div className="blog_img">
                  <img width="100%" src={params?.blogImg10} />
                </div>
              )}
              <div className="title">{params?.title11}</div>
              <div className="desc">{params?.desc11}</div>
              {params?.blogImg11 && (
                <div className="blog_img">
                  <img width="100%" src={params?.blogImg11} />
                </div>
              )}
              <div className="title">{params?.title12}</div>
              <div className="desc">{params?.desc12}</div>
              {params?.blogImg12 && (
                <div className="blog_img">
                  <img width="100%" src={params?.blogImg12} />
                </div>
              )}
              <div className="title">{params?.title13}</div>
              <div className="desc">{params?.desc13}</div>
              {params?.blogImg13 && (
                <div className="blog_img">
                  <img width="100%" src={params?.blogImg13} />
                </div>
              )}
              <div className="title">{params?.title14}</div>
              <div className="desc">{params?.desc14}</div>
              {params?.blogImg14 && (
                <div className="blog_img">
                  <img width="100%" src={params?.blogImg14} />
                </div>
              )}
              <div className="title">{params?.title15}</div>
              <div className="desc">{params?.desc15}</div>
              {params?.blogImg15 && (
                <div className="blog_img">
                  <img width="100%" src={params?.blogImg15} />
                </div>
              )}
              <div className="title">{params?.title16}</div>
              <div className="desc">{params?.desc16}</div>
              {params?.blogImg16 && (
                <div className="blog_img">
                  <img width="100%" src={params?.blogImg16} />
                </div>
              )}
              <div className="title">{params?.title17}</div>
              <div className="desc">{params?.desc17}</div>
              {params?.blogImg17 && (
                <div className="blog_img">
                  <img width="100%" src={params?.blogImg17} />
                </div>
              )}

              <div className="title">{params?.title18}</div>
              <div className="desc">{params?.desc18}</div>
              {params?.blogImg18 && (
                <div className="blog_img">
                  <img width="100%" src={params?.blogImg18} />
                </div>
              )}

              <div className="title">{params?.title19}</div>
              <div className="desc">{params?.desc19}</div>
              {params?.blogImg19 && (
                <div className="blog_img">
                  <img width="100%" src={params?.blogImg19} />
                </div>
              )}
              <div className="title">{params?.title20}</div>
              <div className="desc">{params?.desc20}</div>
              {params?.blogImg20 && (
                <div className="blog_img">
                  <img width="100%" src={params?.blogImg20} />
                </div>
              )}
              <div className="title">{params?.title21}</div>
              <div className="desc">{params?.desc21}</div>
              {params?.blogImg21 && (
                <div className="blog_img">
                  <img width="100%" src={params?.blogImg21} />
                </div>
              )}

              <div className="title">{params?.title23}</div>
              <div className="desc">{params?.desc23}</div>
              {params?.blogImg23 && (
                <div className="blog_img">
                  <img width="100%" src={params?.blogImg23} />
                </div>
              )}
              <div className="title">{params?.title24}</div>
              <div className="desc">{params?.desc24}</div>
              {params?.blogImg24 && (
                <div className="blog_img">
                  <img width="100%" src={params?.blogImg24} />
                </div>
              )}
            </div>
          </Grid>
          <Grid style={{ margin: 10 }} item md={3} xs={12}>
            <div className="blog_terms_wrapper_right">
              <div className="blog_title_right">
                Want to grow your business?
              </div>
              <div className="btn_conatiner">
                <button
                  className="blog_button_right"
                  onClick={() => navigation("/contact")}
                >
                  Contact us
                </button>
              </div>
            </div>
          </Grid>
        </Grid>
        <Footer />
      </div>
    </>
  );
}

export default BlogDetails;
