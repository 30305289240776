import { NoEncryption } from "@material-ui/icons";
import { flexbox } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import "../css/Legacy.css";
import cloud from "../assets/Cloud(1).png";
import block from "../assets/Blockchain.png";
import watch from "../assets/Watch.png";
import tech from "../assets/Tech_Startups.png";
import education from "../assets/Education.png";
import enterprise from "../assets/Enterprise.png";
import insurance from "../assets/Insurance.png";
import mobile from "../assets/mobile(1).png";
import theme from "../theme/color.json";

function Legacy() {
  const responsive = window.innerWidth > 500;

  const navigation = useNavigate();
  return (
    <>
      <style>{dynamicCss}</style>
      <div
        style={{ textAlign: responsive ? "" : "center" }}
        className="Container"
      >
        <div className="heading">
          <h1
            style={{
              display: "flex",
              marginTop: 80,
              justifyContent: "center",
              fontSize: 40,
              fontWeight: 700,
              color: "black",
              fontFamily: "DM Sans"
            }}
          >
            Our Legacy
          </h1>

          <div className="legcy_content_wraper">
            Bitsbay is a team of highly proficient app developers, passionate
            UX/UI designers and creative digital product developers. Who are
            habitual of exceeding client's expectations. Our team has a legacy
            of delivering apps and digital solutions for small & medium
            enterprises in retail, manufacturing, healthcare, ecommerce and
            other sectors. Our approach to software development is unique, we go
            beyond and above by continuous improvement, anticipating. challenges
            and fast paced problem resolution. Our biggest strength is in the
            collaborative nature of our services; We partner with the clients
            and build relationships to understand their business needs and
            goals. We optimize. as deliver much as we can to a product that is
            as close to perfection as possible.
          </div>

          <div className="imgContainer">
            <div className="imgContainer_div">
              <div
                style={{
                  position: "relative",
                  top: "20px"
                }}
              >
                <img
                  style={{
                    position: "relative",
                    top: "-14px"
                  }}
                  src={mobile}
                  alt="pic"
                  width={50}
                  height={50}
                />
                <h1>Mobile</h1>
              </div>
              <div>
                <img src={cloud} alt="pic" width={80} height={70} />

                <h1>Cloud Web</h1>
              </div>
            </div>
            <div className="imgContainer_div">
              <div>
                <img src={block} alt="pic" width={80} height={70} />
                <h1>Blockchain</h1>
              </div>
              <div>
                <img src={watch} alt="pic" width={80} height={70} />
                <h1>Watch</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mulitivertical_wrapper">
        <div className="multi_heading">Multivertical Expertise</div>
        <div className="multi_text_box">
          We have catered every industry and discovered working for every niche.
          This is why, our legacy speaks louder than words.
        </div>

        <div className="multi_small_box">
          <div>
            <img
              src={tech}
              alt="pic"
              height="50px"
              width="150px"
              style={{ background: "red" }}
              className="img-fluid"
            />
            <div className="multi_card_title">Tech Startups</div>
          </div>
          <div>
            <img
              src={education}
              alt="pic"
              style={{ background: "red" }}
              height="50px"
              width="150px"
              className="img-fluid"
            />
            <div className="multi_card_title">Education</div>
          </div>
          <div>
            <img
              src={enterprise}
              alt="pic"
              height="50px"
              style={{ background: "red" }}
              width="150px"
              className="img-fluid"
            />
            <div className="multi_card_title">Enterprise</div>
          </div>
          <div>
            <img
              src={insurance}
              alt="pic"
              height="50px"
              width="150px"
              className="img-fluid"
              style={{ background: "red" }}
            />
            <div className="multi_card_title">Insurance</div>
          </div>
        </div>
      </div>

      {/*<div
        style={{
          background: "#0b70cb",
          color: "white",
          width: "100%",
          textAlign: responsive ? "" : "center",
          marginTop: 30,
          paddingBottom: responsive ? "" : 20,
        }}
      >
        <div>
          <h1
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 60,
            }}
          >
            Multivertical Expertise
          </h1>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
          >
            <h4>
              We have catered every industry and discovered working for every
              niche.
            </h4>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              alignItems: "center",
            }}
          >
            <h4>This is why, our legacy speaks louder than words.</h4>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: 20,
              textAlign: responsive ? "" : "center",
              paddingBottom: 20,
            }}
          >
            <div style={{}}>
              <img
                src="https://origamistudios.us/wp-content/uploads/2018/11/Startups-1.svg"
                alt="pic"
                width={responsive ? 100 : 65}
                height={responsive ? 100 : 65}
              />
              <h1
                style={{
                  color: "white",
                  // marginRight: 10,
                  textAlign: "center",
                  fontSize: responsive ? 16 : 11,
                }}
              >
                Tech Startups
              </h1>
            </div>
            <div>
              <img
                src="https://origamistudios.us/wp-content/uploads/2018/12/Education.svg"
                alt="pic"
                width={responsive ? 100 : 65}
                height={responsive ? 100 : 65}
              />
              <h1
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: responsive ? 16 : 11,
                }}
              >
                Education
              </h1>
            </div>
            <div>
              <img
                src="https://origamistudios.us/wp-content/uploads/2018/11/Enterprise-1.svg"
                alt="pic"
                width={responsive ? 100 : 65}
                height={responsive ? 100 : 65}
              />
              <h1
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: responsive ? 16 : 11,
                }}
              >
                Enterprise
              </h1>
            </div>
            <div style={{}}>
              <img
                src="https://origamistudios.us/wp-content/uploads/2018/12/Insurance.svg"
                alt="pic"
                width={responsive ? 100 : 65}
                height={responsive ? 100 : 65}
              />
              <h1
                style={{
                  color: "white",
                  marginLeft: 15,
                  textAlign: "center",
                  fontSize: responsive ? 16 : 11,
                }}
              >
                Insurance
              </h1>
            </div>
          </div>
        </div>
      </div>*/}
      <div className="sucess_story_wrapper">
        <div className="heading555">
          <h1 className="secc_heaing">Success Stories</h1>
          <div style={{ marginTop: 20, fontSize: 20 }}>
            We at Bitsbay, have a history of dealing with clients from various
            niches. Moreover, we have developed state of the art Desktop based
            analytics tools, small sized ecommerce web and mobile apps and
            customer relationship management systems that are custom based as
            per client's need. We treat each project as our own and build apps
            that are bound to take the business to a next level.
          </div>
          <div>
            <a
              href="https://bitsbayltd.com/projects"
              style={{
                marginTop: 40,
                textDecoration: "none"
              }}
            >
              <div
                className="all_projects_btn"
                style={{
                  fontWeight: 600,

                  width: 200,

                  display: "flex",
                  justifyContent: "center",
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 10,
                  marginBottom: 30,
                  cursor: "pointer",
                  marginTop: 30
                }}
              >
                All Projects
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Legacy;
const dynamicCss = `
.imgContainer h1{

  color:${theme.textGray}
}
.mulitivertical_wrapper{
  background:${theme.bgRed}
}
.multi_heading{
  color:${theme.textWhite}
}
.multi_text_box{
  color:${theme.textWhite}
}
.multi_card_title{
  color:${theme.textWhite}
}
.secc_heaing{
  color:${theme.textBlack}
}
.all_projects_btn{
  background:${theme.bgRed};
  color:${theme.textWhite}
}
`;
