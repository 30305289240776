import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Blog from "../screens/Blog";
import Contact from "../screens/Contact";
import CrossMobileDevelopment from "../screens/CrossMobileDevelopment";
import CustomApplicationDevelopment from "../screens/CustomApplicationDevelopment";
import HomeScreen from "../screens/HomeScreen";
import LeanDevelopment from "../screens/LeanDevelopment";
import Projects from "../screens/Projects";
import SaaSDevelopment from "../screens/SaaSDevelopment";
import Services from "../screens/Services";
import Solutions from "../screens/Solutions";
import IOSAppDevelopment from "../screens/IOSAppDevelopment";
import AndroidAppDevelopment from "../screens/AndroidAppDevelopment";
import CustomWebApps from "../screens/CustomWebApps";
import ProgressiveWebApps from "../screens/ProgressiveWebApps";
import FrontEndServices from "../screens/FrontEndServices";
import Technologies from "../screens/Technologies";
import Company from "../screens/Company";
import IotDevelopment from "../Components/IotDevelopment";
import AugmentedRealityDevelopment from "../screens/AugmentedRealityDevelopment";
import ChatbotDevelopment from "../screens/ChatbotDevelopment";
import PressRelease from "../screens/PressRelease";
import ModalButton from "../Components/ModalButton";
import ProjectsDemos from "../Components/projects-demos";
import AmazonServices from "../screens/AmazonServices";
import AdServices from "../screens/AdServices";
import BlogDetails from "../Components/BlogDetails/BlogDetails";

export default function AllRoute() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/BlogDetails" element={<BlogDetails />} />

        <Route path="/services" element={<Services />} />
        {/* <Route path="/solutions" element={<Solutions />} /> */}
        <Route path="/technologies" element={<Technologies />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/company" element={<Company />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/pressRelease" element={<PressRelease />} />
        <Route path="/chatbotDevelopment" element={<ChatbotDevelopment />} />
        <Route path="/project-demo" element={<ProjectsDemos />} />
        <Route path="/amazonServices" element={<AmazonServices />} />
        <Route path="/adServices" element={<AdServices />} />
        <Route
          path="/androidAppDevelopment"
          element={<AndroidAppDevelopment />}
        />
        <Route path="/IOSAppDevelopment" element={<IOSAppDevelopment />} />
        <Route path="/iotDevelopment" element={<IotDevelopment />} />
        <Route
          path="/augmentedRealityDevelopment"
          element={<AugmentedRealityDevelopment />}
        />
        <Route
          path="/CrossMobileDevelopment"
          element={<CrossMobileDevelopment />}
        />
        <Route path="/CustomWebApps" element={<CustomWebApps />} />
        <Route path="/ProgressiveWebApps" element={<ProgressiveWebApps />} />
        <Route path="/FrontEndServices" element={<FrontEndServices />} />
        <Route path="/SaaSDevelopment" element={<SaaSDevelopment />} />
        <Route path="/LeanDevelopment" element={<LeanDevelopment />} />
        <Route
          path="/CustomApplicationDevelopment"
          element={<CustomApplicationDevelopment />}
        />
      </Routes>
    </Router>
  );
}
