import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Footer from "../Components/Footer";

import NineCards from "../Components/NineCards";
import BuildAppSubmit from "../Components/BuildAppSubmit";
import MetaData from "../Components/MetaData";
import NavBarSec from "../Components/NavBar2";
import RequestButton from "../Components/RequestButton";
import RequestButton2 from "../Components/RequestButton2";

function SaaSDevelopment() {
  const responsive = window.innerWidth > 500;

  return (
    <>
      <NavBarSec />
      <div style={{ textAlign: responsive ? "" : "center" }}>
        <MetaData
          title={"SAAS APP DEVELOPMENT SERVICES Engineered for excellence"}
        />
        <Grid
          md={12}
          style={{
            background: "#5ADFB2",
            display: "flex",
            justifyContent: "center",
            minHeight: 650
          }}
          container
        >
          <Grid item xs={12} md={6}>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginRight: responsive ? "" : 20,
                marginTop: responsive ? "" : 10
              }}
            >
              <div style={{ color: "white" }}>
                <div
                  style={{ fontSize: responsive ? 40 : 25, fontWeight: 700 }}
                >
                  SAAS APP DEVELOPMENT SERVICES Engineered for excellence
                </div>

                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    marginTop: 10,
                    marginLeft: responsive ? 0 : 10
                  }}
                >
                  Bitsbay delivers automated systems that can be fully
                  incorporated into a SaaS network.
                </div>
                <RequestButton />
              </div>
            </div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div style={{ marginTop: 90 }}>
              <img
                width="100%"
                height="100%"
                src={require("../assets/SaasImg.png")}
              />
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            paddingTop: 30,
            padding: 10,
            paddingRight: 10
          }}
        >
          <div></div>
          <div
            style={{
              marginTop: 20,
              fontWeight: 450
            }}
          >
            <div
              style={{
                fontSize: 25,
                fontWeight: 630
              }}
            >
              Enhanced Infrastructure With
            </div>
            <div
              style={{
                fontSize: 25,
                fontWeight: 700
              }}
            >
              SaaS Cloud Services
            </div>
            <div
              style={{
                marginTop: 30,
                fontWeight: 450
              }}
            >
              <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                Owing to the scalability and flexibility it offers, SAAS
                application development services are in demand big time, and we
                are here to develop SAAS mobile and web solutions that can not
                only garner new leads for you but also help you retain your
                loyal consumer-base.
              </div>

              <div
                style={{
                  marginTop: 30,
                  fontWeight: 450,
                  paddingLeft: 10,
                  paddingRight: 10
                }}
              >
                The main reason why Bitsbay is considered an authority for SaaS
                solutions is because your business requirements will be always
                prioritized for specialized and customized SaaS services. We
                craft your idea and divide its creation into phases that can be
                practically achieved. Any unneeded complexity is eradicated from
                the product so that the user-base can quickly adopt. To ensure a
                solid SaaS network, user experience, strong architecture and
                security of the software is prioritized at all times.
              </div>
            </div>
            <div
              style={{
                marginTop: 30,
                fontWeight: 450,
                paddingLeft: 10,
                paddingRight: 10
              }}
            >
              We have implemented the framework system and useful resources that
              upheld the SaaS Development for multiple clients without any
              glitch. Through our sound caliber and proficiency in SaaS
              application development services, we have helped clients get the
              best of time-saving and utility-figuring SaaS solutions. We can
              also help you in building a SaaS application even on an existing
              PaaS (Platform-as-a-Service) utilizing open source cloud
              frameworks like OpenStack or on Java, PHP, Python & Ruby on Rails.
            </div>
          </div>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 30
            }}
            container
          >
            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                borderRight: "1px solid lightgray"
              }}
              item
              xs={12}
              md={3}
            >
              <img
                width="50%"
                height="70%"
                src={require("../assets/SaaSApp.png")}
              />
              <div
                style={{
                  fontWeight: 700,
                  fontSize: 24
                }}
              >
                SaaS App
              </div>
            </Grid>
            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                borderRight: "1px solid lightgray"
              }}
              item
              xs={12}
              md={3}
            >
              <img
                width="50%"
                height="70%"
                src={require("../assets/SaaSSoft.png")}
              />
              <div
                style={{
                  fontWeight: 700,
                  fontSize: 24
                }}
              >
                SaaS Software Development{" "}
              </div>
            </Grid>
            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column"
              }}
              item
              xs={12}
              md={3}
            >
              <img
                width="50%"
                height="70%"
                src={require("../assets/SaaSWeb.png")}
              />
              <div
                style={{
                  fontWeight: 700,
                  fontSize: 24
                }}
              >
                SaaS Web Development
              </div>
            </Grid>
          </Grid>
          <RequestButton2 title="Discuss an app idea now!" />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: 50,
          background: "#E0E1E3",
          paddingRight: 10,
          paddingLeft: 10
        }}
      >
        <div style={{ textAlign: responsive ? "" : "center" }}>
          <div style={{ fontSize: 25, fontWeight: 700 }}>
            Reasons to choose us
          </div>
          <div
            style={{
              marginTop: 50,
              marginRight: responsive ? 80 : 20,
              marginTop: 30,
              marginLeft: 10
            }}
          >
            When it comes to SaaS Application Development Services, we can be
            your ideal partner. We go above and beyond to ensure client
            satisfaction.
          </div>
        </div>
        <Grid container>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 70
            }}
            item
            xs={12}
            md={5}
          >
            <div>
              <img
                width="100%"
                height="auto"
                src={require("../assets/ReasonsImg.png")}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={7}>
            <div
              style={{
                display: responsive ? "" : "flex",
                justifyContent: responsive ? "" : "center",
                alignItems: responsive ? "" : "center",
                flexDirection: responsive ? "" : "column"
              }}
            >
              <div
                style={{
                  fontSize: responsive ? 17 : 14,
                  fontWeight: 400,
                  marginTop: 50
                }}
              >
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Your application thought is ensured using a marked NDA.
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 8 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Get free application consultancy on application
                    development,including upgrade, application adaptation, pre,
                    and post-showcasing system, and so on.
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Agile development process to break your application
                    development procedure.
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    UX/UI specialists to fuel your application with imagination.
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Constant correspondence regarding your app.
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    International quality affirmation models to test your
                    application.
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Complete support for deployment.
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    International coding standards for application advancement.
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>

                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Complete after-sales support
                  </div>
                </div>
              </div>
              <RequestButton2 title="Lets connect" />
            </div>
          </Grid>
        </Grid>
      </div>

      <NineCards
        t1="PLATFORM REQUIREMENTS
      "
        t2="USE CASES
      "
        t3="ARCHITECTURE STRUCTURING
      "
        t4="PROJECT PLAN
      "
        t5="UI UX DESIGN
      "
        t6="SAAS APPLICATION PROGRAMMING
      "
        t7="SAAS TESTING
      "
        t8="DEPLOYMENT"
        t9="MAINTENANCE &
      SUPPORT"
        d1="We accumulate prerequisites and make a task's detail to ensure the client and development team are all on the same page.

     "
        d2="Application features are written in the form of use cases and later shared with the client.

     "
        d3="SaaS application is arranged through multiple tenants, and structuring begins. Most suitable hosting module is chosen.

     "
        d4="We divide the SaaS project development tasks into sprints.

     "
        d5="Once done, the team moves to create UI UX design.

     "
        d6="We seek Agile based development adding adaptability to our development procedure.

     "
        d7="To ensure SaaS security multiple testing approaches like unit testing, relapse and operational are run to monitor seamless operations.

     "
        d8="We ensure that the methodology we adopt to deploy the SAAS product is time-saving and cost-effective for your business.

     "
        d9="Post app launch, we deliver maintenance, quality support and enhancement services as required.
     "
        img1={require("../assets/project.png")}
        img2={require("../assets/use.png")}
        img3={require("../assets/structuring.png")}
        img4={require("../assets/plan.png")}
        img5={require("../assets/design.png")}
        img6={require("../assets/saas.png")}
        img7={require("../assets/sassTest.png")}
        img8={require("../assets/deployment.png")}
        img9={require("../assets/maintenance.png")}
      />

      <div style={{ textAlign: responsive ? "" : "center" }}>
        <BuildAppSubmit />
        <Footer />
      </div>
    </>
  );
}

export default SaaSDevelopment;
