import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import BuildAppSubmit from "../Components/BuildAppSubmit";
import Footer from "../Components/Footer";
import MetaData from "../Components/MetaData";
import Navbar from "../Components/Navbar";
import NineCards from "../Components/NineCards";
import RequestButton from "../Components/RequestButton";
import RequestButton2 from "../Components/RequestButton2";
import NavBarSec from "../Components/NavBar2";

function LeanDevelopment() {
  const responsive = window.innerWidth > 500;

  return (
    <>
      <NavBarSec />
      <div style={{ textAlign: responsive ? "" : "center" }}>
        <MetaData
          title={"IMAGINING A MVP? – Let us build Quality and Deliver Fast!"}
        />
        <Grid
          md={12}
          style={{
            background: "#F9D94E",
            display: "flex",
            justifyContent: "center",
            minHeight: 650
          }}
          container
        >
          <Grid item xs={12} md={6}>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: responsive ? "" : 10
              }}
            >
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <div
                  style={{
                    color: "white",
                    marginLeft: responsive ? 70 : "",
                    marginRight: responsive ? "" : 20
                  }}
                >
                  <div
                    style={{ fontSize: responsive ? 40 : 25, fontWeight: 700 }}
                  >
                    IMAGINING A MVP? – Let us build Quality and Deliver Fast!
                  </div>

                  <div style={{ fontSize: 17, fontWeight: 600, marginTop: 10 }}>
                    We can help you create a working MVP for your app idea
                    within your budgets and timelines.
                  </div>
                  <RequestButton />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={10} md={5}>
            <div style={{ marginTop: 140 }}>
              <img
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex"
                }}
                width="100%"
                height="100%"
                src={require("../assets/Learn.png")}
              />
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            paddingTop: 40,
            paddingLeft: 10,
            paddingBlockStart: 10
          }}
        >
          <div>
            <div
              style={{
                fontSize: 25,
                fontWeight: 630
              }}
            >
              Affordable and Rapid
            </div>
            <div
              style={{
                fontSize: 25,
                fontWeight: 700
              }}
            >
              MVP Development Services
            </div>
            <div
              style={{
                marginTop: 30,
                fontWeight: 450
              }}
            >
              <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                Building an MVP can validate your development strategy by
                testing a minimal form of your product idea in the market! An
                MVP can help you build the bare minimum version to understand
                what works, what fails, and how can you gradually scale it to
                what you want. Choosing the right MVP development services can
                make all the difference in creating a functional MVP.
              </div>
              <div
                style={{
                  marginTop: 30,
                  fontSize: 25,
                  fontWeight: 650
                }}
              >
                Giving You What Your Business Needs
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: 30
            }}
          >
            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
              Adhering to cost and time constraints of our clients, we at
              Bitsbay deliver workable & quality MVP development services which
              serve to get the ball rolling for our customers. The core of the
              MVP relies on great functionality which can be scaled with time.
              Our group of advisors and business experts offer MVP ideas that
              are time-efficient and financially savvy.
            </div>
          </div>
          <RequestButton2 title="I need an MVP. Let’s discuss" />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: 50,
          background: "#E0E1E3",
          paddingRight: 10,
          paddingLeft: 10
        }}
      >
        <div
          style={{
            textAlign: responsive ? "" : "center"
          }}
        >
          <div style={{ fontSize: 25, fontWeight: 700 }}>
            Reasons to choose us
          </div>
          <div style={{ marginTop: 30, paddingLeft: 10, paddingRight: 10 }}>
            Our thorough understanding and keen ability gives us an edge over
            other agile development companies and empowers us to deliver results
            that guarantee tangible customer satisfaction. Our MVP development
            processes includes:
          </div>
        </div>
        <Grid container>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 70
            }}
            item
            xs={12}
            md={5}
          >
            <div>
              <img
                width="100%"
                height="auto"
                src={require("../assets/ReasonsImg.png")}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={7}>
            <div
              style={{
                display: responsive ? "" : "flex",
                justifyContent: responsive ? "" : "center",
                alignItems: responsive ? "" : "center",
                flexDirection: responsive ? "" : "column"
              }}
            >
              <div
                style={{
                  fontSize: responsive ? 17 : 14,
                  fontWeight: 400,
                  marginTop: 50
                }}
              >
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Protection of your idea through signed NDA
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Provision of solutions that meet your budget and time frame
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Division of development into sprints and management via PM
                    tools
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Product is prototyped before moving into development
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    The flexibility of work processes using agile development
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Communication is maintained via Skype, Trello or Slack
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    Manual and automated checks are by our quality assurance
                    engineers to ensure the product is free of bugs
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    The code is committed regularly to ensure transparency in
                    the process of code handovers
                  </div>
                </div>
                <div
                  style={{
                    marginTop: responsive ? 10 : 0,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>

                  <div
                    style={{
                      marginTop: responsive ? 5 : 5
                    }}
                  >
                    End-to-End deployment, as well as testing services, are
                    delivered
                  </div>
                </div>
                <div
                  style={{
                    marginTop: 10,
                    display: "flex"
                  }}
                >
                  <div>
                    <img src={require("../assets/right.png")} />
                  </div>
                  <div>Post-deployment support services are rendered</div>
                </div>
              </div>
              <RequestButton2 title="Lets connect" />
            </div>
          </Grid>
        </Grid>
      </div>
      <NineCards
        t1="PROJECT REQUIREMENTS
        "
        t2="USE CASES
        "
        t3="PROJECT PLAN
        "
        t4="WIREFRAMES & PROTOTYPES
        "
        t5="DESIGN
        "
        t6="AGILE DEVELOPMENT
        "
        t7="TESTING & QA
        "
        t8="RELEASE
        "
        t9="MAINTENANCE &
        SUPPORT"
        d1="A dedicated team member gathers project requirements from the client.

        "
        d2="The requirements are then used to draft use cases of the application.

        "
        d3="Project development is divided into sprints and managed via PM tool.

        "
        d4="App wireframes are developed for the client to get an idea of the final product.

        "
        d5="The wireframes are used as reference to create application designs.

        "
        d6="Project development is done sprint wise with regular code reviews and testing.

        "
        d7="After completion, QA testing is conducted to ensure there are no bugs.

        "
        d8="The product is then uploaded to live and made available to the target audience.

        "
        d9="Post app launch, we deliver maintenance, quality support and enhancement services as required.

        "
        img1={require("../assets/project.png")}
        img2={require("../assets/use.png")}
        img3={require("../assets/plan.png")}
        img4={require("../assets/Wireframing.png")}
        img5={require("../assets/design.png")}
        img6={require("../assets/agile.png")}
        img7={require("../assets/testing.png")}
        img8={require("../assets/release.png")}
        img9={require("../assets/maintenance.png")}
      />

      <div style={{ textAlign: responsive ? "" : "center" }}>
        <BuildAppSubmit />
        <Footer />
      </div>
    </>
  );
}

export default LeanDevelopment;
