import React from "react";
import { Grid } from "@mui/material";
import Footer from "../Components/Footer";

import NavBarSec from "../Components/NavBar2";
import BuildAppSubmit from "../Components/BuildAppSubmit";
import { BsCheckLg } from "react-icons/bs";
import "../css/Service.css";
import Streamline from "../assets/streamline.png";
import RocketLeptop from "../assets/rocketLeptop.png";
import Moneytree from "../assets/moneytree.png";
function AmazonServices() {
  const responsive = window.innerWidth > 500;
  return (
    <>
      <NavBarSec />
      <div>
        <div
          md={12}
          style={{
            backgroundColor: "#273272",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 400,
            paddingLeft: responsive ? "" : 10,
          }}
          container
        >
          <div item xs={12} md={5}>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: responsive ? "center" : "",
                textAlign: responsive ? "center" : "",
              }}
            >
              <div style={{ color: "white" }}>
                <div
                  style={{
                    fontSize: 19,

                    marginTop: 10,
                    marginRight: responsive ? 0 : 10,
                    marginLeft: responsive ? 0 : 10,
                    fontFamily: "Brookline",
                    color: "#ffc13c",
                  }}
                >
                  SELL MORE WITH OUR FULL-SERVICE MANAGEMENT FOR AMAZON PROGRAM
                </div>
                <div
                  style={{
                    fontSize: responsive ? 62 : 34,
                    fontWeight: 800,
                    marginRight: responsive ? 0 : 10,
                    marginLeft: responsive ? 0 : 10,
                    fontFamily: "Axiforma, Sans-serif",
                  }}
                >
                  Managed Services for Amazon
                </div>
              </div>
            </div>
          </div>
        </div>
        <Grid
          md={12}
          style={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            minHeight: 500,
            marginTop: 50,
            paddingLeft: responsive ? "" : 20,
            paddingRight: responsive ? "" : 20,
          }}
          container
        >
          <Grid item xs={12} md={5}>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: 19,
                    fontWeight: "normal",

                    marginRight: responsive ? 0 : 10,
                    paddingRight: responsive ? 40 : 0,
                    color: "#111010",
                    width: responsive ? 560 : "100%",
                    fontFamily: "Axiforma, Sans-serif",
                    lineHeight: "31px",
                  }}
                >
                  Our done-for-you management approach ensures your account on
                  Amazon is not only running smoothly but your sales are also
                  growing, too. <strong>Our team handles everything</strong>{" "}
                  from paid advertising to organic marketing campaigns to
                  operations management (including brand protection).
                </div>
                <button
                  style={{
                    fontWeight: "700",
                    fill: "#273272",
                    color: "#273272",
                    backgroundColor: " #FFC13C",
                    border: "none",
                    borderRadius: 40,
                    padding: "15px 20px 15px 20px",
                    marginTop: 50,
                    width: "auto",
                    fontSize: 18,
                  }}
                >
                  <a className="amazon_service_btn" href={"#class"}>
                    GET A FREE ANALYSIS
                  </a>
                </button>
              </div>
            </div>
          </Grid>
          <Grid item xs={10} md={5}>
            <div
              style={{
                marginTop: 30,
                display: "flex",
                justifyContent: responsive ? "flex-end" : "center",
              }}
            >
              <img
                width={responsive ? 500 : 300}
                src={require("../assets/amazonServices.jpg")}
              />
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Axiforma, Sans-serif",
            fontSize: 17,
            fontWeight: "normal",
            lineHeight: "31px",
            marginTop: 50,
            paddingLeft: responsive ? "" : 20,
            paddingRight: responsive ? "" : 20,
          }}
        >
          <div
            style={{
              maxWidth: responsive ? 1140 : "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                color: "#111010",
                fontSize: responsive ? 40 : 26,
                padding: responsive ? "0px 180px 0px 180px" : 0,
                fontWeight: 800,
                lineHeight: "1.3em",
                letterSpacing: 0,
                display: "flex",
                textAlign: "center",
              }}
            >
              Grow Your Sales on Amazon With Our Proprietary Management Method
            </div>
            <div
              style={{
                textAlign: "center",
                color: " #111010",
                fontSize: 21,
                marginTop: 20,
                fontWeight: 400,
              }}
            >
              It can feel impossible to increase sales on Amazon while also
              running and developing your business. That’s where we come in. We
              optimize your content, systems, and advertising. Your sales will
              grow and you can get back to developing your business.
            </div>

            <Grid
              style={{
                width: "100%",
                marginTop: 70,
              }}
              container
            >
              <Grid item xs={12} md={4}>
                <div>
                  <div
                    style={{
                      margin: 2,
                      padding: "20px 20px 5px 20px",
                      backgroundImage:
                        "url(https://www.velocitysellers.com/wp-content/uploads/2021/11/count-bg.png)",
                      backgroundPosition: "center center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      textAlign: "center",
                      fontSize: 52,
                      lineHeight: "2em",
                    }}
                  >
                    <p style={{ textAlign: "center", fontSize: 52 }}>
                      <strong>1</strong>
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: 20,
                      fontWeight: 600,
                      fontSize: 17,
                    }}
                  >
                    <strong>STRATEGY</strong>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 20,
                      fontWeight: 600,
                      fontFamily: "Axiforma, Sans-serif",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ display: "flex", marginTop: 10 }}>
                        <div>
                          <BsCheckLg style={{ color: "#ffc13c" }} />
                        </div>
                        <div style={{ marginLeft: 15 }}>
                          Analyze Products & Opportunities
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <BsCheckLg style={{ color: "#ffc13c" }} />
                        </div>
                        <div style={{ marginLeft: 10 }}>Set Goals</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <BsCheckLg style={{ color: "#ffc13c" }} />
                        </div>
                        <div style={{ marginLeft: 10 }}>
                          Customize Action Plan
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid
                style={{ marginTop: responsive ? 0 : 20 }}
                item
                xs={12}
                md={4}
              >
                <div>
                  <div
                    style={{
                      margin: 2,
                      padding: "20px 20px 5px 20px",
                      backgroundImage:
                        "url(https://www.velocitysellers.com/wp-content/uploads/2021/11/count-bg.png)",
                      backgroundPosition: "center center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      textAlign: "center",
                      fontSize: 52,
                      lineHeight: "2em",
                    }}
                  >
                    <p style={{ textAlign: "center", fontSize: 52 }}>
                      <strong>2</strong>
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: 20,
                      fontWeight: 600,
                      fontSize: 17,
                    }}
                  >
                    <strong>LAUNCH</strong>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 20,
                      fontWeight: 600,
                      fontFamily: "Axiforma, Sans-serif",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ display: "flex", marginTop: 10 }}>
                        <div>
                          <BsCheckLg style={{ color: "#ffc13c" }} />
                        </div>
                        <div style={{ marginLeft: 15 }}>
                          Set Competitive Price Points
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <BsCheckLg style={{ color: "#ffc13c" }} />
                        </div>
                        <div style={{ marginLeft: 10 }}>
                          Develop Keyword & Optimize Listings
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <BsCheckLg style={{ color: "#ffc13c" }} />
                        </div>
                        <div style={{ marginLeft: 10 }}>
                          Utilize or Improve High ROI Ads
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid
                style={{ marginTop: responsive ? 0 : 20 }}
                item
                xs={12}
                md={4}
              >
                <div>
                  <div
                    style={{
                      margin: 2,
                      padding: "20px 20px 5px 20px",
                      backgroundImage:
                        "url(https://www.velocitysellers.com/wp-content/uploads/2021/11/count-bg.png)",
                      backgroundPosition: "center center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      textAlign: "center",
                      fontSize: 52,
                      lineHeight: "2em",
                    }}
                  >
                    <p style={{ textAlign: "center", fontSize: 52 }}>
                      <strong>3</strong>
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: 20,
                      fontWeight: 600,
                      fontSize: 17,
                    }}
                  >
                    <strong>GROWTH</strong>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 20,
                      fontWeight: 600,
                      fontFamily: "Axiforma, Sans-serif",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ display: "flex", marginTop: 10 }}>
                        <div>
                          <BsCheckLg style={{ color: "#ffc13c" }} />
                        </div>
                        <div style={{ marginLeft: 15 }}>
                          Evaluate New Marketplaces
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <BsCheckLg style={{ color: "#ffc13c" }} />
                        </div>
                        <div style={{ marginLeft: 10 }}>
                          Manage Your Reviews
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <BsCheckLg style={{ color: "#ffc13c" }} />
                        </div>
                        <div style={{ marginLeft: 10 }}>
                          Refine Pricing Strategies as Needed
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <div
              class="elementor-shape elementor-shape-bottom"
              data-negative="false"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1000 100"
                preserveAspectRatio="none"
              >
                <path
                  class="elementor-shape-fill"
                  d="M0,6V0h1000v100L0,6z"
                ></path>
              </svg>{" "}
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#f0eef1",
            marginTop: 120,
            paddingBottom: 50,

            paddingLeft: responsive ? "" : 20,
            paddingRight: responsive ? "" : 20,
            display: "flex",
            justifyContent: "center",
            fontFamily: "Axiforma, Sans-serif",
          }}
        >
          <div style={{ maxWidth: responsive ? 1140 : "100%" }}>
            <div
              style={{
                backgroundColor: "white",

                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                padding: responsive
                  ? "50px 100px 50px 50px"
                  : "15px 15px 15px 15px",
                alignItems: "center",
                fontSize: responsive ? 40 : 26,
                fontWeight: 800,
                color: "#111010",
              }}
            >
              Start Growing Your Brand On Amazon Today
            </div>
            <div
              style={{
                marginTop: 30,
                padding: responsive ? " 0px 320px 0px 320px" : 0,
                textAlign: "center",
                color: " #111010",
                fontSize: 21,
                fontWeight: 400,
              }}
            >
              Stop feeling left behind and start increasing your sales with this
              simple plan ⸺
            </div>
            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: responsive ? 70 : 0,
              }}
              container
            >
              <Grid item xs={12} md={4}>
                <div
                  style={{
                    marginTop: responsive ? 0 : 20,
                    background: "white",
                    borderRadius: 20,
                    padding: 20,
                    marginRight: responsive ? 20 : 0,
                    height: 150,
                  }}
                >
                  <img src={Streamline} />

                  <div
                    style={{
                      textAlign: "center",
                      justifyContent: "centar",
                      width: "100%",
                      marginTop: 10,
                    }}
                  >
                    <strong>Get your free analysis</strong>
                  </div>
                </div>
              </Grid>
              <Grid style={{}} item xs={12} md={4}>
                <div
                  style={{
                    marginTop: responsive ? 0 : 20,
                    background: "white",
                    borderRadius: 20,
                    padding: 20,
                    marginRight: responsive ? 20 : 0,
                    height: 150,
                  }}
                >
                  <img src={RocketLeptop} />

                  <div
                    style={{
                      textAlign: "center",
                      justifyContent: "centar",
                      width: "100%",
                      marginTop: 10,
                    }}
                  >
                    <strong>Kick-off your customized plan</strong>
                  </div>
                </div>
              </Grid>
              <Grid style={{}} item xs={12} md={4}>
                <div
                  style={{
                    marginTop: responsive ? 0 : 20,
                    background: "white",
                    borderRadius: 20,
                    padding: 20,
                    height: 150,
                  }}
                >
                  <img style={{}} width={50} height={50} src={Moneytree} />

                  <div
                    style={{
                      textAlign: "center",
                      justifyContent: "centar",
                      width: "100%",
                      marginTop: 10,
                    }}
                  >
                    <strong>
                      Maximize your brand’s full revenue potential on Amazon
                    </strong>
                  </div>
                </div>
              </Grid>
            </Grid>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                style={{
                  fontWeight: "700",
                  fill: "#273272",
                  color: "#273272",
                  backgroundColor: " #FFC13C",
                  border: "none",
                  borderRadius: 40,
                  padding: "15px 20px 15px 20px",
                  marginTop: 50,
                  width: "auto",
                  fontSize: 18,
                }}
              >
                <a className="amazon_service_btn" href={"#class"}>
                  GET A FREE ANALYSIS
                </a>
              </button>
            </div>
          </div>
        </div>
        <BuildAppSubmit />
        <Footer />
      </div>
    </>
  );
}

export default AmazonServices;
