import { Grid } from "@mui/material";
import React from "react";
import BuildAppSubmit from "../Components/BuildAppSubmit";
import Footer from "../Components/Footer";
import MetaData from "../Components/MetaData";
import Navbar from "../Components/Navbar";
import NavBarSec from "../Components/NavBar2"
import RequestButton from "../Components/RequestButton";
import theme from '../theme/color.json'

function CustomWebApps() {
  const responsive = window.innerWidth > 500;

  return (
    <>
      <style>{dynamicCss}</style>
      <NavBarSec />
      <div className="text_black" style={{ textAlign: responsive ? "" : "center" }}>
        <MetaData
          title={"Custom Web Application Development at minimum prices"}
        />
        <Grid
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: 650
          }}
          container
          className="custom_development_header"
        >
          <Grid item xs={12} md={5}>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginLeft: responsive ? "" : 5,
                marginRight: responsive ? "" : 5,
                marginTop: responsive ? "" : 10
              }}
            >
              <div className="custom_development_header_text">
                <div
                  style={{
                    fontSize: responsive ? 40 : 25,
                    fontWeight: 700,
                    marginRight: responsive ? 0 : 10,
                    marginLeft: responsive ? 0 : 10
                  }}
                >
                  Custom Web Application Development at minimum prices
                </div>

                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    marginTop: 10,
                    marginRight: responsive ? 0 : 10,
                    marginLeft: responsive ? 0 : 10
                  }}
                >
                  Secure Custom Web Solutions tailored to meet your business
                  requirements within your timeframe and budget.
                </div>
                <RequestButton />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={5}>
            <div
              style={{
                marginTop: 60,
                display: "flex",
                justifyContent: responsive ? "flex-end" : "center"
              }}
            >
              <img width="100%" src={require("../assets/CustomWeb.png")} />
            </div>
          </Grid>
        </Grid>

        <div
          className="custom_development_main"
         
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginLeft: 30,
              marginRight: 30,
              marginTop: 50,
              paddingTop: 50
            }}
          >
            <div style={{ fontSize: 25, fontWeight: 700 }}>
              Our Custom Web App Development Approach
            </div>
            <div style={{ fontSize: 17, fontWeight: 600, marginTop: 10 }}>
              An experience ensuring best results, befitting your business
              needs.
            </div>
          </div>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              marrginTop: "50"
            }}
            container
          >
            <Grid style={{}} item xs={12} md={4}>
              <div style={{ marginTop: 50, marginBottom: 50 }}>
                <img
                  src={require("../assets/requirement.png")}
                  style={{
                    marginLeft: 50,
                    marginRight: 50,
                    maxWidth: 90,
                    maxHeight: 90
                  }}
                />
                <div
                  style={{
                    marginLeft: 50,
                    marginRight: 50,
                    fontWeight: 700,
                    fontSize: 24,
                    marginTop: 25
                  }}
                >
                  Requirement and Consultation
                </div>
                <div className="custom_card_des"
                  style={{
                    marginLeft: 50,
                    marginRight: 50,
                    
                    marginTop: 20,
                    fontSize: 18
                  }}
                >
                  Our team of web development experts perform analysis of your
                  business needs and requirements. On the basis of which the
                  most relevant web stack is opted to begin with.
                </div>
              </div>
            </Grid>
            <Grid style={{}} item xs={12} md={4}>
              <div style={{ marginTop: 50, marginBottom: 50 }}>
                <img
                  src={require("../assets/discovery.png")}
                  style={{
                    marginLeft: 50,
                    marginRight: 50,
                    maxWidth: 90,
                    maxHeight: 90
                  }}
                />
                <div
                  style={{
                    marginLeft: 50,
                    marginRight: 50,
                    fontWeight: 700,
                    fontSize: 24,
                    marginTop: 25
                  }}
                >
                  Discovery and Planning
                </div>
                <div className="custom_card_des"
                  style={{
                    marginLeft: 50,
                    marginRight: 50,
                    
                    marginTop: 20,
                    fontSize: 18
                  }}
                >
                  The project roadmap is explicitly defined and detailed project
                  requirements are documented to ensure the developed web app
                  covers all of your business requirements.
                </div>
              </div>
            </Grid>
            <Grid style={{}} item xs={12} md={4}>
              <div style={{ marginTop: 50, marginBottom: 50 }}>
                <img
                  src={require("../assets/Prototyping.png")}
                  style={{
                    marginLeft: 50,
                    marginRight: 50,
                    maxWidth: 90,
                    maxHeight: 90
                  }}
                />
                <div
                  style={{
                    marginLeft: 50,
                    marginRight: 50,
                    fontWeight: 700,
                    fontSize: 24,
                    marginTop: 20
                  }}
                >
                  Prototyping
                </div>
                <div className="custom_card_des"
                  style={{
                    marginLeft: 50,
                    marginRight: 50,
                    
                    marginTop: 20,
                    fontSize: 18
                  }}
                >
                  We create a visualization of the concept to evaluate the
                  application’s feasibility in terms of real implementation
                  along with the assessment of the long-term potential of the
                  web application.
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Grid style={{ marginTop: 30 }} container>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginLeft: 30,
              marginRight: 30,
              marginTop: 30,
              width: "100%"
            }}
          >
            <div style={{ fontSize: 25, fontWeight: 700 }}>
              Engagement Models
            </div>
            <div style={{ fontSize: 17, fontWeight: 600, marginTop: 10 }}>
              As a Custom App Development agency, we provide you with varieties
              of engagement models facilitating your needs at maximum.
            </div>
          </div>
          <Grid item xs={12} md={4}></Grid>
        </Grid>
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: 30
          }}
          container
        >
          <Grid item xs={12} md={4}>
            <div
              style={{
                marginTop: responsive ? 20 : 10,
                marginBottom: 50,
                padding: 30
              }}
            >
              <img
                src={require("../assets/Fixed.png")}
                style={{
                  marginLeft: 50,
                  marginRight: 50,
                  maxWidth: 75,
                  maxHeight: 75
                }}
              />
              <div
                style={{
                  marginLeft: 50,
                  marginRight: 50,
                  fontWeight: 700,
                  fontSize: 24,
                  marginTop: 25
                }}
              >
                Fixed Price Model
              </div>
              <div className="custom_card_des"
                style={{
                  marginLeft: 30,
                  marginRight: 30,
                  
                  marginTop: 20,
                  fontSize: 18
                }}
              >
                In this model, we finalize the cost at the start of the project.
                It suits projects with defined scope and requirements which
                remain same through the business tenure. Besides, it ensures
                on-time and on-budget delivery.
              </div>
            </div>
          </Grid>
          <Grid style={{}} item xs={12} md={4}>
            <div
              style={{
                marginTop: responsive ? 20 : 10,
                marginBottom: 50,
                padding: 30
              }}
            >
              <img
                src={require("../assets/Material.png")}
                style={{
                  marginLeft: 50,
                  marginRight: 50,
                  maxWidth: 75,
                  maxHeight: 75
                }}
              />
              <div
                style={{
                  marginLeft: 50,
                  marginRight: 50,
                  fontWeight: 700,
                  fontSize: 24,
                  marginTop: 25
                }}
              >
                Time and Material Based Model
              </div>
              <div className="custom_card_des"
                style={{
                  marginLeft: 30,
                  marginRight: 30,
                  
                  marginTop: 20,
                  fontSize: 18
                }}
              >
                This model pays for the time and resources used on a specific
                project. This approach gives more room for flexibility and
                adjusting requirements. It reflects agile methodology and is a
                popular choice by most clients.
              </div>
            </div>
          </Grid>
          <Grid style={{}} item xs={12} md={4}>
            <div
              style={{
                marginTop: responsive ? 20 : 10,
                marginBottom: 50,
                padding: 30
              }}
            >
              <img
                src={require("../assets/Dedicated.png")}
                style={{
                  marginLeft: 50,
                  marginRight: 50,
                  maxWidth: 75,
                  maxHeight: 75
                }}
              />
              <div
                style={{
                  marginLeft: 50,
                  marginRight: 50,
                  fontWeight: 700,
                  fontSize: 24,
                  marginTop: 37
                }}
              >
                Dedicated Teams
              </div>
              <div className="custom_card_des"
                style={{
                  marginLeft: 30,
                  marginRight: 30,
                  
                  marginTop: 20,
                  fontSize: 18
                }}
              >
                This is the best option if you have a long-term project. Hiring
                a dedicated team keeps you more involved in the development
                process. Depending upon the expertise you require, we provide
                with the most suitable candidate.
              </div>
            </div>
          </Grid>
        </Grid>
        <BuildAppSubmit />
        <Footer />
      </div>
    </>
  );
}

export default CustomWebApps;
const dynamicCss = `
.custom_development_header{
  background:${theme.bgLightPurple}
}
.custom_development_header_text{
  color:${theme.textWhite}
}
.custom_development_main{
  background:${theme.bgGray};
  color:${theme.textBlack}
}
.custom_card_des{
  color:${theme.textGray}
}
.text_black{
  color:${theme.textBlack}
}
`;