import Navbar from "../Components/Navbar";
import React from "react";
import { Grid } from "@mui/material";
import Footer from "../Components/Footer";
import BuildAppSubmit from "../Components/BuildAppSubmit";
import ThreeCards from "../Components/ThreeCards";
import TwoCards from "../Components/TwoCards";
import MetaData from "../Components/MetaData";
import RequestButton from "../Components/RequestButton";
import NavBarSec from "../Components/NavBar2";
import theme from "../theme/color.json"
function AndroidAppDevelopment() {
  const responsive = window.innerWidth > 500;
  const styling = true;
  return (
    <>
      <style>{dynamicCss}</style>
      <NavBarSec />
      <div>
        <MetaData
          title={"Android Application Development Service that help you grow!"}
        />

        <Grid
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: 650
          }}
          container
          className="android_development_header"
        >
          <Grid item xs={12} md={5}>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: responsive ? "" : "center"
              }}
            >
              <div className="android_development_header_text">
                <div
                  style={{
                    fontSize: responsive ? 40 : 25,
                    fontWeight: 700,
                    marginRight: responsive ? 0 : 10,
                    marginLeft: responsive ? 0 : 10
                  }}
                >
                  Android Application Development Service that help you grow!
                </div>
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    marginTop: 10,
                    marginRight: responsive ? 0 : 10,
                    marginLeft: responsive ? 0 : 10
                  }}
                >
                  We develop high-performing app solutions for Android based
                  devices.
                </div>
                <RequestButton />
              </div>
            </div>
          </Grid>
          <Grid item xs={10} md={5}>
            <div
              style={{
                marginTop: 30,
                display: "flex",
                justifyContent: responsive ? "flex-end" : "center"
              }}
            >
              <img width="60%" src={require("../assets/android.png")} />
            </div>
          </Grid>
        </Grid>

        <div
          className="android_development_main"
          style={{
            textAlign: responsive ? "" : "center"
          }}
        >
          <Grid container md={12} style={{ paddingTop: 25, paddingBottom: 25 }}>
            <Grid item md={1}></Grid>
            <Grid md={10}>
              <div style={{ fontSize: 25, fontWeight: 700 }}>
                The Value We Offer Our Clients
              </div>
              <div style={{ fontSize: responsive ? 18 : 18, marginTop: 20 }}>
                We treat our clients like a part of the Bitsbay family and
                ensure their experience with us is hassle-free and transparent.
              </div>
            </Grid>
          </Grid>
          <TwoCards
            title1="Post-Delivery Support and Maintenance"
            title2="Flexible Project Structuring"
            card1="We provide you with post-launch support and maintenance services that allow you to keep your apps up and running even after they are created on your Android phone."
            card2="We structure each project as per the client’s needs to ensure his satisfaction at every development step. To do this, we diversify our team to provide maximum coverage across all platforms and technologies."
            img1={require("../assets/post.png")}
            img2={require("../assets/Flexible.png")}
          />
          <TwoCards
            title1="Timely Delivery of App Updates"
            title2="Free Consultation to Discuss Your Idea"
            card1="You’ll have access to a dedicated technical project manager who will help you stay on top of deadlines using collaborative tools for constant communication. We believe in delivering timely updates without compromising on quality assurance."
            card2="We believe every idea is worth hearing so that you can share your app idea with us anytime. We’ll provide you with valuable insights into how to make an app that plays a significant role in the growth of your business."
            img1={require("../assets/Delivery.png")}
            img2={require("../assets/Discuss.png")}
          />
        </div>
        <div
          className="android_development_main"
          style={{
            textAlign: responsive ? "" : "center",
            paddingBottom: responsive ? 40 : 20
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginLeft: 30,
              marginRight: 30
            }}
          >
            <div
              style={{
                fontSize: 25,
                fontWeight: 700,
                marginBottom: 20,
                marginTop: 30
              }}
            >
              Our Android App Development Process
            </div>
            <div style={{ textAlign: "center" }}>
              We follow a well-defined process and development strategy to
              create Android apps, from brainstorming to the final delivery. Our
              experts map out the requirements of your business goals and go
              through several rounds of discussion to ensure that all the bases
              are covered before the actual development starts.
            </div>
          </div>
          <ThreeCards
            styling
            title1="Project Requirements"
            title2="Wireframing"
            title3="Use Cases"
            card1="Collect all the requirements from the client because all iOS application development begins with understanding your needs. We create technical documents that outline the project features and functionalities."
            card2="We create wireframes for your Android application to show user journeys. The prototype building of the product is done next which is a critical step – to test the feasibility of your idea.This helps you understand about app usability and overall look on the mobile screen. "
            card3="A business analyst will outline and define application flows in the use case document . The use case document will also help in defining the screen flows and designing Android application architecture.Our business analysts will guide you in every step.
          "
            img1={require("../assets/project.png")}
            img2={require("../assets/Wireframing.png")}
            img3={require("../assets/use.png")}
            style1={{
              margin: 12,
              borderRadius: 20,

              padding: 10,
              height: 310
            }}
          />
          <ThreeCards
            styling
            title1="UI Design"
            title2="Project plan"
            title3="Development Phase"
            card1="The next stage is designing, which consists of understanding each screen’s functionality, determining how it interacts with other screens, and what kind of data should be loaded in it.UI is very important for creating a reliable product."
            card2="We create a project plan that outlines each iteration’s milestones, tasks, and deliverables following the agile methodology. We make sure that all the features are built according to your requirements."
            card3="This is an iterative process where programming code is written for new features in every iteration. Our Android application developers make sure that you get your application on the play store from scratch to a running appas soon as possible. "
            img1={require("../assets/UI.png")}
            img2={require("../assets/plan.png")}
            img3={require("../assets/Development.png")}
            style1={{
              margin: 12,
              borderRadius: 20,

              padding: 10,
              height: 310
            }}
          />
          <ThreeCards
            styling
            title1="Quality Assurance"
            title2="App Launch"
            title3="App Distribution & Maintenance"
            card1="Once the development is done, we start testing for bugs and errors on android device. After a thorough clean-up process, we pass on the final version to you for approval. Your feedback is then incorporated into the next iteration of your project."
            card2="After you approve the app, we submit it to the google play store. Once it’s live on the store, several things need to be done, such as setting up a developer account with Google, configuring your application in play protect, checking the android version, and more."
            card3="Once your Android mobile app goes live on the Google play store, we help you promote it through various channels. We also provide support post-launch with quick turnaround times. If any issues crop up in the future due to an update from Google or any changes made by your team, we will take care of them at minimal costs. "
            img1={require("../assets/Quality.png")}
            img2={require("../assets/app.png")}
            img3={require("../assets/maintenance.png")}
            style1={{
              margin: 12,
              borderRadius: 20,

              padding: 10,
              height: 355
            }}
          />
        </div>

        <BuildAppSubmit />

        <Footer />
      </div>
    </>
  );
}

export default AndroidAppDevelopment;
const dynamicCss = `
.android_development_header{
  background:${theme.bgSkyBlue}
}
.android_development_header_text{
  color:${theme.textWhite}
}
.android_development_main{
  background:${theme.bgGray};
  color:${theme.textBlack}
}
`;