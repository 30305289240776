import { Alert, Grid, Modal } from "@mui/material";
import React, { useRef, useState } from "react";
import Navbar from "../Components/Navbar";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import BusinessIcon from "@mui/icons-material/Business";
// import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MetaData from "../Components/MetaData";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import NavBarSec from "../Components/NavBar2";
import contect from "../assets/images(3).jpg";
// import emailjs from '@emailjs/browser';
import emailjs from "emailjs-com";
import Footer from "../Components/Footer";

const loginSchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
  email: Yup.string().required("An email is required"),
  phone: Yup.string().required("This field is required"),
  company: Yup.string().required("This field is required"),
});
const style = {
  marginTop: 10,
  margin: 2,
};
function Contact() {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  console.log(message, "message");
  const [checkMessage, setCheckMessage] = useState(false);
  console.log(checkMessage, "checkMessage");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const [email, setEmail] = useState("");
  const formref = useRef();
  // const [phone, setPhone] = useState("");
  // const [company, setCompany] = useState("");
  // const [name, setName] = useState("");
  const [client, setClient] = useState("");

  // const handleEmail = (e) => {
  //   setEmail(e.target.value);
  // };
  // const handleName = (e) => {
  //   setName(e.target.value);
  // };
  // const handlePhone = (e) => {
  //   setPhone(e.target.value);
  // };
  // const handleCompany = (e) => {
  //   setCompany(e.target.value);
  // };
  const handleClient = (e) => {
    setClient(e.target.value);
  };

  const handleSubmit = async (values, resetForm) => {
    // const serviceID = "service_geldb9c";
    // const templateID = "template_t4jgl44";
    // const publicKey = "jvn69GsKwLAvlO7iB";
    // const templateParams= values;

    console.log(values, "values");
    if (message === "") {
      setCheckMessage(!checkMessage);
    } else {
      // const endPoint =
      //   "https://public.herotofu.com/v1/8a4b45d0-58f7-11ef-8878-1bb2cdb41a19";
      // console.log(values, "myValues");
      // fetch(endPoint, {
      //   method: "POST",
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify(values),
      // }).then((res) => {
      //   console.log(res);
      //   resetForm();
      //   setMessage("");
      //   setCheckMessage(false);
      //   setOpen(true);
      //   // window.location.reload();
      // });
      const serviceId = "service_wfyb3vi";
      const templateId = "template_4feqe3k";
      const userId = "o1sGgvXOrjeloB-wp";

      const response = await emailjs.send(
        serviceId,
        templateId,
        {
          name: values.name,
          email: values.email,
          phone: values.phone,
          company: values.company,
          message: message,
        },
        userId
      );

      if (response.status === 200) {
        console.log("Email sent successfully", response.status, response.text);
        console.log("Email sent successfully", response.status, response.text);
        resetForm();
        window.location.reload();
      } else {
        console.error("Failed to send email", response.status, response.text);
        console.error("Failed to send email", response.status, response.text);
      }
    }
  };
  const responsive = window.innerWidth > 500;
  return (
    <>
      <NavBarSec />
      <div
        style={{
          marginBottom: responsive ? 200 : 0,
          textAlign: responsive ? "" : "center",
        }}
      >
        <MetaData title={"Bitsbay | Contact "} />
        <div>
          <img width="100%" height="800px" src={contect} />
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Alert
                severity="success"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                We will contact soon
              </Alert>
            </Box>
          </Modal>
          <div
            style={{
              position: "absolute",
              top: 130,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              fontFamily: "DM Sans",
            }}
          >
            <div
              style={{
                fontSize: responsive ? 50 : 32,
                fontWeight: 700,
                color: "white",
                marginTop: 30,
              }}
            >
              LET’S GET TO THE NEXT LEVEL TOGETHER
            </div>
            <div
              style={{
                fontSize: 20,
                fontWeight: 600,
                color: "white",
                marginTop: 20,
                fontFamily: "DM Sans",
              }}
            >
              Bitsbay is always ready to discuss new ideas ranging from Mobile
              App Development to any ideas with Web design and
            </div>
            <div
              style={{
                fontSize: 20,
                fontWeight: 600,
                color: "white",
                fontFamily: "DM Sans",
              }}
            >
              services. Come discuss your potential app ideas with us over a cup
              of coffee!
            </div>
            <div
              style={{
                fontSize: 20,
                fontWeight: 700,
                color: "white",
                marginTop: 20,
                fontFamily: "DM Sans",
              }}
            >
              We would be happy to hear from you
            </div>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                width: "30%",
                marginTop: 5,
                fontFamily: "DM Sans",
              }}
            >
              <Grid container md={12} style={{ display: "flex" }}>
                <Grid item xs={12} md={6}>
                  <a
                    href="tel:+923160499968"
                    className="allBorder"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: 10,
                      paddingBottom: 10,
                      marginRight: 10,
                      color: "white",
                      fontWeight: 600,
                      fontSize: 17,
                      textDecoration: "none",
                      fontFamily: "DM Sans",
                    }}
                  >
                    Call us now
                  </a>
                </Grid>
                <Grid item md={6} xs={12}>
                  <a
                    href="mailto:support@bitsbayltd.com"
                    className="allBorder"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: 10,
                      paddingBottom: 10,
                      marginLeft: 10,
                      color: "white",
                      fontWeight: 600,
                      fontSize: 17,
                      textDecoration: "none",
                      fontFamily: "DM Sans",
                    }}
                  >
                    Drop an Email!
                  </a>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                width: "80%",
              }}
            >
              <Grid container md={12} xs={12} style={{ display: "flex" }}>
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  item
                  xs={12}
                  md={6}
                >
                  <a
                    className="allBorder"
                    href="tel:+923160499968"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: 10,
                      paddingBottom: 10,
                      color: "white",
                      fontWeight: 600,
                      fontSize: 17,
                      width: "100%",
                      marginTop: 20,
                      textDecoration: "none",
                      fontFamily: "DM Sans",
                    }}
                  >
                    Call us now
                  </a>
                </Grid>
                <Grid
                  style={{ display: "flex", justifyContent: "center" }}
                  item
                  md={6}
                  xs={12}
                >
                  <a
                    className="allBorder"
                    href="mailto:support@bitsbayltd.com"
                    type="email"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: 10,
                      paddingBottom: 10,
                      color: "white",
                      fontWeight: 600,
                      fontSize: 17,
                      width: "100%",
                      marginTop: 20,
                      textDecoration: "none",
                      fontFamily: "DM Sans",
                    }}
                  >
                    Drop an Email!
                  </a>
                </Grid>
              </Grid>
            </Box>
            <div
              style={{
                fontSize: 20,
                fontWeight: 700,
                color: "white",
                marginTop: 20,
                fontFamily: "DM Sans",
              }}
            >
              Or simply fill the form below:
            </div>
            <div
              style={{
                backgroundColor: "white",
                width: responsive ? "70%" : "75%",
                marginTop: responsive ? 40 : 20,
                height: responsive ? "75vh" : "90vh",
                boxShadow: "1px 2px 9px #DFDFDF",
                marginBottom: 30,
                fontFamily: "DM Sans",
              }}
            >
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  width: "100%",
                }}
              >
                <Grid md={12} xs={12} container>
                  <Grid
                    item
                    md={8}
                    style={{
                      display: "flex",
                      height: "100%",
                      fontFamily: "DM Sans",
                    }}
                  >
                    <div
                      style={{
                        marginTop: 20,
                        marginBottom: 20,
                      }}
                    >
                      <Formik
                        innerRef={formref}
                        initialValues={{
                          name: "",
                          email: "",
                          phone: "",
                          company: "",
                        }}
                        validationSchema={loginSchema}
                        onSubmit={(values, { resetForm }) => {
                          handleSubmit(values, resetForm);
                        }}
                      >
                        <Form>
                          <div
                            style={{
                              marginLeft: responsive ? 70 : 50,
                              marginRight: responsive ? 70 : 50,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <Field
                                component={TextField}
                                margin="normal"
                                fullWidth
                                name="name"
                                label="Your Name"
                                type="name"
                                id="name"
                                disabled={false}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <PersonIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                variant="standard"
                              />
                              <Field
                                style={{ marginLeft: 50 }}
                                component={TextField}
                                margin="normal"
                                fullWidth
                                name="email"
                                label="Email"
                                type="email"
                                id="email"
                                disabled={false}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <EmailIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                variant="standard"
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <Field
                                component={TextField}
                                margin="normal"
                                fullWidth
                                name="phone"
                                label="Phone"
                                type="phone"
                                id="phone"
                                disabled={false}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <PhoneIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                variant="standard"
                              />
                              <Field
                                style={{ marginLeft: 50 }}
                                component={TextField}
                                margin="normal"
                                fullWidth
                                name="company"
                                label="Company"
                                type="company"
                                id="company"
                                disabled={false}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <BusinessIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                variant="standard"
                              />
                            </div>

                            <div>
                              <textarea
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="Share your product idea with confidence. Your concept will be confidential and protected by a non-disclosure agreement."
                                style={{
                                  borderWidth: 0,
                                  width: "100%",
                                  marginTop: 15,
                                }}
                                label="message"
                                type="message"
                                id="message"
                                name="message"
                                rows="5"
                              />

                              <div
                                style={{
                                  backgroundColor: "lightgray",
                                  height: 1,
                                  width: "100%",
                                }}
                              />

                              {checkMessage && (
                                <div style={{ color: "red" }}>
                                  message is required field
                                </div>
                              )}
                              <div
                                style={{
                                  marginTop: 50,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                ></div>
                                <button
                                  type="submit"
                                  style={{
                                    backgroundColor: "#3D76E4",
                                    color: "white",
                                    fontWeight: 600,
                                    fontSize: 16,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    paddingRight: 30,
                                    paddingLeft: 30,
                                    borderRadius: 10,
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textTransform: "none",
                                    borderWidth: 0,
                                    fontFamily: "DM Sans",
                                  }}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </Formik>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    style={{
                      backgroundColor: "#3D76E4",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        color: "white",
                        fontWeight: 600,
                        marginLeft: 25,
                        marginRight: 25,
                        marginTop: 40,
                      }}
                    >
                      Got an app idea & need to get it validated? Let us give
                      you our honest opinion.
                    </div>
                    <div
                      style={{
                        color: "white",
                        fontWeight: 600,
                        marginLeft: 25,
                        marginRight: 25,
                        marginTop: 20,
                      }}
                    >
                      Book your FREE consultation with our experts
                    </div>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  display: { xs: "block", md: "none" },
                  width: "100%",
                }}
              >
                <Grid md={12} xs={12} container>
                  <Grid
                    item
                    md={8}
                    style={{
                      display: "flex",
                      height: "75%",
                    }}
                  >
                    <div
                      style={{
                        marginTop: 30,
                      }}
                    >
                      <Formik
                        innerRef={formref}
                        initialValues={{
                          name: "",
                          email: "",
                          phone: "",
                          company: "",
                        }}
                        validationSchema={loginSchema}
                        onSubmit={(values, { resetForm }) => {
                          handleSubmit(values, resetForm);
                        }}
                      >
                        <Form>
                          <div
                            style={{
                              marginLeft: responsive ? 90 : 20,
                              marginRight: responsive ? 90 : 20,
                              marginTop: 20,
                              marginBottom: 20,
                            }}
                          >
                            <Field
                              component={TextField}
                              margin="normal"
                              fullWidth
                              name="name"
                              label="Your Name"
                              type="name"
                              id="name"
                              disabled={false}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonIcon />
                                  </InputAdornment>
                                ),
                              }}
                              variant="standard"
                            />
                            <Field
                              component={TextField}
                              margin="normal"
                              fullWidth
                              name="email"
                              label="Email"
                              type="email"
                              id="email"
                              disabled={false}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <EmailIcon />
                                  </InputAdornment>
                                ),
                              }}
                              variant="standard"
                            />

                            <Field
                              component={TextField}
                              margin="normal"
                              fullWidth
                              name="phone"
                              label="Phone"
                              type="phone"
                              id="phone"
                              disabled={false}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PhoneIcon />
                                  </InputAdornment>
                                ),
                              }}
                              variant="standard"
                            />
                            <Field
                              component={TextField}
                              margin="normal"
                              fullWidth
                              name="company"
                              label="Company"
                              type="company"
                              id="company"
                              disabled={false}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <BusinessIcon />
                                  </InputAdornment>
                                ),
                              }}
                              variant="standard"
                            />

                            <div>
                              <textarea
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="Share your product idea with confidence. Your concept will be confidential and protected by a non-disclosure agreement."
                                style={{
                                  borderWidth: 0,
                                  width: "100%",
                                  marginTop: 15,
                                }}
                                label="message"
                                type="message"
                                id="message"
                                name="message"
                                rows="5"
                              />

                              <div
                                style={{
                                  backgroundColor: "lightgray",
                                  height: 1,
                                  width: "100%",
                                }}
                              />

                              {checkMessage && (
                                <div style={{ color: "red" }}>
                                  message is required field
                                </div>
                              )}
                              <div
                                style={{
                                  marginTop: 50,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                ></div>
                                <button
                                  type="submit"
                                  style={{
                                    backgroundColor: "#3D76E4",
                                    color: "white",
                                    fontWeight: 600,
                                    fontSize: 16,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    paddingRight: 30,
                                    paddingLeft: 30,
                                    borderRadius: 10,
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textTransform: "none",
                                    borderWidth: 0,
                                  }}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </Formik>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    style={{
                      backgroundColor: "#3D76E4",
                      // height: "30%"
                    }}
                  >
                    <div
                      style={{
                        color: "white",
                        fontWeight: 600,
                        marginLeft: 25,
                        marginRight: 25,
                        marginTop: 40,
                      }}
                    >
                      Got an app idea & need to get it validated? Let us give
                      you our honest opinion.
                    </div>
                    <div
                      style={{
                        color: "white",
                        fontWeight: 600,
                        marginLeft: 25,
                        marginRight: 25,
                        marginTop: 20,
                      }}
                    >
                      Book your FREE consultation with our experts
                    </div>
                  </Grid>
                </Grid>

                <Footer />
              </Box>
            </div>
          </div>
        </div>
      </div>
      {window.innerWidth > 800 && <Footer />}
    </>
  );
}

export default Contact;
