import { Grid } from "@mui/material";
import React from "react";
import theme from '../theme/color.json'

const responsive = window.innerWidth > 500;

function NineCards({
  t1,
  t2,
  t3,
  t4,
  t5,
  t6,
  t7,
  t8,
  t9,
  d1,
  d2,
  d3,
  d4,
  d5,
  d6,
  d7,
  d8,
  d9,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
}) {
  return (
    <>
      <style>{ dynamicCss }</style>
    <div>
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 30,
        }}
        container
      >
        <Grid
          md={10}
          style={{
            fontSize: 25,
            fontWeight: 700,
            marginTop: 30,
            marginBottom: 80,
            textAlign: responsive ? "" : "center",
          }}
        >
          Bitsbay Road To Successful Application Development
        </Grid>
        <Grid
          style={{
            boxShadow: "1px 2px 9px lightgray",
            marginLeft: 10,
            marginRight: 10,
            paddingLeft: 10,
            paddingRight: 10,
            marginBottom: 20,
          }}
          item
          xs={10}
          md={3}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              paddingRight: 3,
              
              fontWeight: 700,
            }}
            className="nine_card_count"
          >
            01
          </div>
          <div style={{ marginTop: 10, marginBottom: 30, display: "flex" }}>
            <img width="17%" height="17%" src={img1} />
            <div>
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  fontWeight: 700,
                  fontSize: 20,
                }}
              >
                {t1}
              </div>
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 30,
                 
                  marginTop: 20,
                  fontSize: 16,
                }}
                className="nine_cards_des"
              >
                {d1}
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          style={{
            boxShadow: "1px 2px 9px lightgray",
            marginLeft: 10,
            marginRight: 10,
            paddingLeft: 10,
            paddingRight: 10,
            marginBottom: 20,
          }}
          item
          xs={10}
          md={3}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              paddingRight: 3,
              
              fontWeight: 700,
            }}
            className="nine_card_count"
          >
            02
          </div>
          <div style={{ marginTop: 10, marginBottom: 30, display: "flex" }}>
            <img width="15%" height="15%" src={img2} />
            <div>
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  fontWeight: 700,
                  fontSize: 20,
                }}
              >
                {t2}
              </div>
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 30,
                 
                  marginTop: 20,
                  fontSize: 16,
                }}
                className="nine_cards_des"
              >
                {d2}
              </div>
            </div>
          </div>
        </Grid>{" "}
        <Grid
          style={{
            boxShadow: "1px 2px 9px lightgray",
            marginLeft: 10,
            marginRight: 10,
            paddingLeft: 10,
            paddingRight: 10,
            marginBottom: 20,
          }}
          item
          xs={10}
          md={3}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              paddingRight: 3,
              
              fontWeight: 700,
            }}
            className="nine_card_count"
          >
            03
          </div>
          <div style={{ marginTop: 10, marginBottom: 30, display: "flex" }}>
            <img width="17%" height="17%" src={img3} />
            <div>
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  fontWeight: 700,
                  fontSize: 20,
                }}
              >
                {t3}
              </div>
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 30,
                 
                  marginTop: 20,
                  fontSize: 16,
                }}
                className="nine_cards_des"
              >
                {d3}
              </div>
            </div>
          </div>
        </Grid>{" "}
        <Grid
          style={{
            boxShadow: "1px 2px 9px lightgray",
            marginLeft: 10,
            marginRight: 10,
            paddingLeft: 10,
            paddingRight: 10,
            marginBottom: 20,
          }}
          item
          xs={10}
          md={3}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              paddingRight: 3,
              
              fontWeight: 700,
            }}
            className="nine_card_count"
          >
            04
          </div>
          <div style={{ marginTop: 10, marginBottom: 30, display: "flex" }}>
            <img width="17%" height="17%" src={img4} />
            <div>
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  fontWeight: 700,
                  fontSize: 20,
                }}
              >
                {t4}
              </div>
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 30,
                 
                  marginTop: 20,
                  fontSize: 16,
                }}
                className="nine_cards_des"
              >
                {d4}
              </div>
            </div>
          </div>
        </Grid>{" "}
        <Grid
          style={{
            boxShadow: "1px 2px 9px lightgray",
            marginLeft: 10,
            marginRight: 10,
            paddingLeft: 10,
            paddingRight: 10,
            marginBottom: 20,
          }}
          item
          xs={10}
          md={3}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              paddingRight: 3,
              
              fontWeight: 700,
            }}
            className="nine_card_count"
          >
            05
          </div>
          <div style={{ marginTop: 10, marginBottom: 30, display: "flex" }}>
            <img width="15%" height="15%" src={img5} />
            <div>
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  fontWeight: 700,
                  fontSize: 20,
                }}
              >
                {t5}
              </div>
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 30,
                 
                  marginTop: 20,
                  fontSize: 16,
                }}
                className="nine_cards_des"
              >
                {d5}
              </div>
            </div>
          </div>
        </Grid>{" "}
        <Grid
          style={{
            boxShadow: "1px 2px 9px lightgray",
            marginLeft: 10,
            marginRight: 10,
            paddingLeft: 10,
            paddingRight: 10,
            marginBottom: 20,
          }}
          item
          xs={10}
          md={3}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              paddingRight: 3,
              
              fontWeight: 700,
            }}
            className="nine_card_count"
          >
            06
          </div>
          <div style={{ marginTop: 10, marginBottom: 30, display: "flex" }}>
            <img width="17%" height="17%" src={img6} />
            <div>
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  fontWeight: 700,
                  fontSize: 20,
                }}
              >
                {t6}
              </div>
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 30,
                 
                  marginTop: 20,
                  fontSize: 16,
                }}
                className="nine_cards_des"
              >
                {d6}
              </div>
            </div>
          </div>
        </Grid>{" "}
        <Grid
          style={{
            boxShadow: "1px 2px 9px lightgray",
            marginLeft: 10,
            marginRight: 10,
            paddingLeft: 10,
            paddingRight: 10,
            marginBottom: 20,
          }}
          item
          xs={10}
          md={3}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              paddingRight: 3,
              
              fontWeight: 700,
            }}
            className="nine_card_count"
          >
            07
          </div>
          <div style={{ marginTop: 10, marginBottom: 30, display: "flex" }}>
            <img width="15%" height="15%" src={img7} />
            <div>
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  fontWeight: 700,
                  fontSize: 20,
                }}
              >
                {t7}
              </div>
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 30,
                 
                  marginTop: 20,
                  fontSize: 16,
                }}
                className="nine_cards_des"
              >
                {d7}
              </div>
            </div>
          </div>
        </Grid>{" "}
        <Grid
          style={{
            boxShadow: "1px 2px 9px lightgray",
            marginLeft: 10,
            marginRight: 10,
            paddingLeft: 10,
            paddingRight: 10,
            marginBottom: 20,
          }}
          item
          xs={10}
          md={3}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              paddingRight: 3,
              
              fontWeight: 700,
            }}
            className="nine_card_count"
          >
            08
          </div>
          <div style={{ marginTop: 10, marginBottom: 30, display: "flex" }}>
            <img width="17%" height="17%" src={img8} />
            <div>
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  fontWeight: 700,
                  fontSize: 20,
                }}
              >
                {t8}
              </div>
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 30,
                 
                  marginTop: 20,
                  fontSize: 16,
                }}
                className="nine_cards_des"
              >
                {d8}
              </div>
            </div>
          </div>
        </Grid>{" "}
        <Grid
          style={{
            boxShadow: "1px 2px 9px lightgray",
            marginLeft: 10,
            marginRight: 10,
            paddingLeft: 10,
            paddingRight: 10,
            marginBottom: 20,
          }}
          item
          xs={10}
          md={3}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              paddingRight: 3,
              
              fontWeight: 700,
            }}
            className="nine_card_count"
          >
            09
          </div>
          <div style={{ marginTop: 10, marginBottom: 30, display: "flex" }}>
            <img width="25%" height="25%" src={img9} />
            <div>
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  fontWeight: 700,
                  fontSize: 20,
                }}
              >
                {t9}
              </div>
              <div
                style={{
                  marginLeft: 10,
                  marginRight: 30,
                 
                  marginTop: 20,
                  fontSize: 16,
                }}
                className="nine_cards_des"
              >
                {d9}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
    </>
  );
}

export default NineCards;
const dynamicCss = `
.nine_cards_des{
  color:${theme.textGray}
}
.nine_card_count{
  color:${theme.textBlue}
}
`;